import React, { useEffect, useState } from 'react'

export default function Servives() {

    const [tab, setTab] = useState(0)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <section class="product__details--tab__section section--padding">
            <div class="container">
                <div class="row row-cols-1">
                    <div class="col">
                        <ul class="product__tab--one product__details--tab d-flex mb-30">
                            <li class={`product__details--tab__list ${tab === 0 && "active"}`} data-toggle="tab" data-target="#" onClick={() => setTab(0)}>Analytical Services</li>
                            <li class={`product__details--tab__list ${tab === 1 && "active"}`} data-toggle="tab" data-target="#reviews" onClick={() => setTab(1)}>Synthetic Services</li>
                        </ul>
                        <div class="product__details--tab__inner border-radius-10">
                            <div class="tab_content">
                                <div class={`tab_pane ${tab === 0 && "active show"}`}>
                                    <div class="product__tab--content">
                                        <div class="product__tab--content__step mb-30">
                                            <h2 class="product__tab--content__title h4 mb-10">PADM Laboratories Pvt ltd</h2>
                                            <p class="product__tab--content__desc">Our Analytical labs offer a wide range of capabilities, and they are supported by a team of skilled analyst who can provide the quality results.</p>
                                        </div>
                                        <div class="product__tab--content__step style2 d-flex align-items-center mb-30">
                                            <div class="product__tab--content__banner">
                                                <img class="product__tab--content__banner--img display-block" src="assets/img/other/lab1.jpg" alt="banner-img" />
                                            </div>
                                            <div class="product__tab--content__right">
                                                <div class="image__with--text__percent product__tab--percent__style">
                                                    <ul>
                                                        <li class="about__content--desc__list">HPLC Assay and RS by pharmacopeial method</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Method Development for API & drug products </li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Method validation according to ICH guidelines and/or customer requirements</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Analytical Method Transfer </li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Dissolution Studies for finished drug products.</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Chiral Purity Analysis</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Isolation & Separation by Preparative HPLC</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Unknown impurity profiling using LC-MS & LC- MSMS</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">FT-IR Analysis</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">NMR, Analysis (1H, CMR, COSY, HMQC, etc.)</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Residual Solvents</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Loss on Drying (LOD)</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Moisture Content</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Specific Optical Rotation (SOR)</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Elemental Analysis (C, H, N)</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Differential Scanning Calorimetry (DSC)</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Thermo Gravimetric Analysis (TGA)</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Viscosity </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class={`tab_pane ${tab === 1 && "active show"}`}>
                                    <div class="product__tab--content">
                                        <div class="product__tab--content__step mb-30">
                                            <h2 class="product__tab--content__title h4 mb-10">PADM Chem Research Pvt ltd</h2>
                                            <p class="product__tab--content__desc">Our group of skilled Ph.D. chemists specialises in creating the best and most cost-effective synthetic routes for both novel and known substances. We provide synthesis services that include the synthesis of intermediates, impurities, standard / reference compounds, and building blocks for complex molecules.</p>
                                        </div>
                                        <div class="product__tab--content__step style2 d-flex align-items-center mb-30">
                                            <div class="product__tab--content__banner">
                                                <img class="product__tab--content__banner--img display-block" src="assets/img/other/lab3.jpg" alt="banner-img" />
                                            </div>
                                            <div class="product__tab--content__right">
                                                <div class="image__with--text__percent product__tab--percent__style">
                                                    <ul>
                                                        <li class="about__content--desc__list">Small Molecule Synthesis </li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Isolation & Structure Elucidation of Unknown Impurities</li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Synthesis of Impurities & Intermediates </li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Natural products isolation </li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Scale-up Synthesis from milligrams to kilograms </li>
                                                    </ul>
                                                    <ul>
                                                        <li class="about__content--desc__list">Process Development </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
