import React, { useEffect, useState } from 'react'
import axios from '../../assets/axios/axios'
import { useSelector } from 'react-redux'
import StoresApi from '../../assets/api/StoreApis'
import CustomerAddressModal from '../../components/CustomerAddressForm'
import AddressCard from '../../components/AddressCard'

export default function Addresses() {
    const store = useSelector(state => state.storeFront.store)
    const user = useSelector(state => state.auth.currentUser)

    const [addressModal, setAddressModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [validate, setValidate] = useState(false);
    const [customer, setCustomer] = useState({})
    const [customerAddresses, setCustomerAddresses] = useState([])
    const [selectedAddress, setSelectedAddress] = useState({})

    const getData = () => {
        axios.get(`${StoresApi.CustomersUrl}/GetCustomer/${user.customers.id}`, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        })
            .then(response => {
                setCustomer(response.data.data)
                setCustomerAddresses(response.data.data.customerAddresses.filter(address => address.isDeleted === false))
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    const closeAddressModal = () => {
        setAddressModal(false)
        setSelectedAddress({})
        setValidate(false)
    }

    const addNewAddress = (data) => {
        axios.post(`${StoresApi.CustomerAddressesUrl}/AddCustomerAddress`, data)
            .then(response => {
                closeAddressModal()
                getData()
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    const updateAddress = (data) => {
        axios.put(`${StoresApi.CustomerAddressesUrl}/UpdateCustomerAddress`, data)
            .then(response => {
                closeAddressModal()
                getData()
            })
            .catch(error => {
                console.log(error);
            })
    }

    const handleAddressSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("Validation fails");
        } else {
            const data = {
                storeId: store.id,
                customerId: user.customersId,
                name: selectedAddress.name,
                mobileNumber: selectedAddress.mobileNumber,
                pinCode: selectedAddress.pinCode,
                addressLine1: selectedAddress.addressLine1,
                addressLine2: selectedAddress.addressLine2,
                landMark: selectedAddress.landMark,
                city: selectedAddress.city,
                stateId: selectedAddress.stateId,
                addressType: selectedAddress.addressType,

            }
            console.log(data);
            if (selectedAddress.id) {
                data.Id = selectedAddress.id
                data.modifiedBy = user.customersId
                updateAddress(data)
            } else {
                data.createdBy = user.customersId
                addNewAddress(data)
            }
        }
        setValidate(true)
    }

    const handleAddressInput = (e) => {
        const regex = /^[0-9]*$/
        if (e.target.name === 'mobileNumber') {
            if (regex.test(e.target.value)) {
                setSelectedAddress({ ...selectedAddress, mobileNumber: e.target.value })
            }
        } else {
            setSelectedAddress({ ...selectedAddress, [e.target.name]: e.target.value })
        }
    }

    const editAddress = (ind) => {
        const address = customerAddresses[ind]
        setSelectedAddress(address)
        setAddressModal(true)
    }

    const setAsDefaultAddress = (id) => {
        const data = {
            id,
            storeId: store.id,
            customerId: customer.id
        }
        axios.put(`${StoresApi.CustomerAddressesUrl}/UpdateCustomerDefaultAddress`, data)
            .then(response => {
                getData()
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    const confirmDeleteAddress = (id) => {
        const address = customerAddresses[id]
        setSelectedAddress(address)
        setConfirmModal(true)
    }

    const deleteAddress = () => {
        axios.delete(`${StoresApi.CustomerAddressesUrl}/DeleteCustomerAddress/${selectedAddress.id}`)
            .then(response => {
                setConfirmModal(false)
                setSelectedAddress({})
                getData()
            })
            .catch(error => {
                console.log(error);
            })
    }
    return (
        <>
            <section class="my__account--section">
                <div class="container">
                    <div class="account__wrapper">
                        <div class="account__content">
                            <h2 class="account__content--title h3 mb-20">Addresses</h2>
                            <button class="new__address--btn primary__btn mb-25" type="button" onClick={() => setAddressModal(true)}>Add a new address</button>
                            {customerAddresses.length > 0 &&
                                <div class="row">
                                    {customerAddresses.map((address, ind) => {
                                        return (
                                            <>
                                                <AddressCard address={address} ind={ind} editAddress={editAddress} confirmDeleteAddress={confirmDeleteAddress} setAsDefaultAddress={setAsDefaultAddress} addEdit />
                                            </>
                                        )
                                    })}
                                </div>}
                        </div>
                    </div>
                </div>
            </section>
            <CustomerAddressModal showModal={addressModal} closeModal={closeAddressModal} setShowModal={setAddressModal} validate={validate} handleInput={handleAddressInput} handleSubmit={handleAddressSubmit} details={selectedAddress} />
        </>
    )
}
