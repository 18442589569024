import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper'
import axios from '../assets/axios/axios'
import StoresApi from '../assets/api/StoreApis'
import { useDispatch, useSelector } from 'react-redux'
import { addStore } from '../redux/slices/storeSlice'
import ListContent from './list/ListContent'
import { Link } from 'react-router-dom'

export default function Home() {
    const dispatch = useDispatch()
    const store = useSelector(state => state.storeFront.store)

    const [products, setProducts] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const getData = (categoryId) => {
        axios.get(`${StoresApi.PriceListUrl}/getCategoryWiseProducts/${store.id}/${categoryId}`)
            .then(response => {
                const activeProducts = response.data.data.filter(product => product.product.status)
                setProducts(activeProducts.slice(0, 3))
                // dispatch(addProducts(response.data.data))
            })
            .catch(error => {
                console.log(error.response);
                // setIsError(true)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {

        if (store.id) {
            getData(21)
            // getData(2)
        } else {
            axios.get(`${StoresApi.StoreUrl}/GetStoreByCode/${"Padmlabs"}`)
                .then(response => {
                    dispatch(addStore(response.data.data))
                })
                .catch(error => {
                    console.log(error.response);
                })
        }
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <main class="main__content_wrapper">
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    speed={500}
                    centeredSlides={true}
                    modules={[Autoplay, Navigation]}
                    // className='hero__slider--activation'
                    navigation
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}

                >
                    <SwiperSlide>
                        <img class=" display-block" src="/assets/img/banner/padm_banner1.jpg" alt="slider img" style={{ maxWidth: "100%", margin: 'auto' }} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img class=" display-block" src="/assets/img/banner/padm_banner2.jpg" alt="slider img" style={{ maxWidth: "100%", margin: 'auto' }} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img class=" display-block" src="/assets/img/banner/padm_banner3.jpg" alt="slider img" style={{ maxWidth: "100%", margin: 'auto' }} />
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <img class=" display-block" src="/assets/img/banner/padm_banner.jpg" alt="slider img" style={{ maxWidth: "100%", margin:'auto' }} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img class=" display-block" src="/assets/img/banner/padm_banner_2.jpg" alt="slider img" style={{ maxWidth: "100%", margin:'auto' }} />
                    </SwiperSlide> */}
                </Swiper>
                {/* <img src="/assets/img/banner/padm_banner.jpg" alt="slider img" width="100%" /> */}
                {/*<section class="hero__slider--section slider__section">
                   <div class="hero__slider--inner position__relative">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <Swiper
                                        spaceBetween={30}
                                        slidesPerView={1}
                                        speed={500}
                                        centeredSlides={true}
                                        modules={[Autoplay]}
                                        className='hero__slider--activation'
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false,
                                        }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        loop={true}

                                    >
                                        <SwiperSlide>
                                            <div>
                                                <div>
                                                    <img class=" display-block" src="http://padmlab.com/wp-content/uploads/2017/03/092.jpg" alt="slider img" />
                                                </div>
                                                <div class="slider__content text-center">
                                                    <h2 class="slider__content--maintitle h1">Natural Materials Drone Earthy </h2>
                                                    <p class="slider__content--desc d-sm-2-none">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in alley of type and scrambled it some.</p>
                                                    <div class="slider__content--footer d-flex align-items-center justify-content-center">
                                                        <a class="slider__content--btn primary__btn" href="shop.html">Shop Now</a>
                                                        <div class="bideo__play slider__play--bideo">
                                                            <a class="bideo__play--icon glightbox" href="https://vimeo.com/115041822" data-gallery="video">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 31 37">
                                                                    <path data-name="Polygon 1" d="M16.783,2.878a2,2,0,0,1,3.435,0l14.977,25.1A2,2,0,0,1,33.477,31H3.523a2,2,0,0,1-1.717-3.025Z" transform="translate(31) rotate(90)" fill="currentColor"></path>
                                                                </svg>
                                                                <span class="visually-hidden">Play</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div class="hero__slider--items">
                                                <div class="hero__slider--thumbnail">
                                                    <img class="hero__slider--thumbnail__img display-block" src="http://padmlab.com/wp-content/uploads/2017/03/023.jpg" alt="slider img" />
                                                </div>
                                                <div class="slider__content text-center">
                                                    <h2 class="slider__content--maintitle h1">Natural Materials Drone Earthy </h2>
                                                    <p class="slider__content--desc d-sm-2-none">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in alley of type and scrambled it some.</p>
                                                    <div class="slider__content--footer d-flex align-items-center justify-content-center">
                                                        <a class="slider__content--btn primary__btn" href="shop.html">Shop Now</a>
                                                        <div class="bideo__play slider__play--bideo">
                                                            <a class="bideo__play--icon glightbox" href="https://vimeo.com/115041822" data-gallery="video">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 31 37">
                                                                    <path data-name="Polygon 1" d="M16.783,2.878a2,2,0,0,1,3.435,0l14.977,25.1A2,2,0,0,1,33.477,31H3.523a2,2,0,0,1-1.717-3.025Z" transform="translate(31) rotate(90)" fill="currentColor"></path>
                                                                </svg>
                                                                <span class="visually-hidden">Play</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div class="hero__slider--items">
                                                <div class="hero__slider--thumbnail">
                                                    <img class="hero__slider--thumbnail__img display-block" src="http://padmlab.com/wp-content/uploads/2017/03/padm9.jpg" alt="slider img" />
                                                </div>
                                                <div class="slider__content text-center">
                                                    <h2 class="slider__content--maintitle h1">Natural Materials Drone Earthy </h2>
                                                    <p class="slider__content--desc d-sm-2-none">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in alley of type and scrambled it some.</p>
                                                    <div class="slider__content--footer d-flex align-items-center justify-content-center">
                                                        <a class="slider__content--btn primary__btn" href="shop.html">Shop Now</a>
                                                        <div class="bideo__play slider__play--bideo">
                                                            <a class="bideo__play--icon glightbox" href="https://vimeo.com/115041822" data-gallery="video">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 31 37">
                                                                    <path data-name="Polygon 1" d="M16.783,2.878a2,2,0,0,1,3.435,0l14.977,25.1A2,2,0,0,1,33.477,31H3.523a2,2,0,0,1-1.717-3.025Z" transform="translate(31) rotate(90)" fill="currentColor"></path>
                                                                </svg>
                                                                <span class="visually-hidden">Play</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                        <div class="slider__pagination swiper-pagination"></div>
                    </div> 
                </section>*/}

                <section class="services__section services__section--bg section--padding">
                    <div class="container">
                        <div class="section__heading text-center mb-50">
                            <h2 class="section__heading--maintitle text__secondary mb-10">Welcome to PADM</h2>
                            <p class="section__heading--desc">PADM has state of art laboratory approved by state and central regulatory body. It has all the modern day analytical instruments to fulfill customers every need. Stringent testing and strict documentation practices ensures the high quality of product and services provided to the customers. Meeting committed timelines at all the time with high quality product and service adds credibility to PADM.</p>
                        </div>
                        <div class="services__inner">
                            <div class="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-2 mb--n30">
                                <div class="col-6 custom-col mb-30">
                                    {/* <article class="services__card">
                                        <Link class="services__card--link" to="/products">
                                            <div class="services__card--topbar d-flex justify-content-between">
                                                <div class="services__card--icon mb-20">
                                                    <img class="display-block services__card--primary__icon" src="/assets/img/icon/service-icon1.png" />
                                                    <img class="display-block services__card--secondary__icon" src="/assets/img/icon/service-icon1-white.png" />
                                                </div>
                                                <div class="services__card--number">
                                                    <span class="services__card--number__text">1</span>
                                                </div>
                                            </div>
                                            <div class="services__card--content">
                                                <h3 class="services__card--maintitle mb-15">Products</h3>
                                                <p class="services__card--desc mb-15">Every team has a culture and set of ct ations that have either been aed orsve naturally evolved.</p>
                                                <span class="services__card--readmore text__secondary"> Read More
                                                    <svg class="services__card--readmore__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                                                </span>
                                            </div>
                                        </Link>
                                    </article> */}
                                    <Link to="/category?char=a" className='card--link'>
                                        <div class="featured__product--card mb-30">
                                            <div class="featured__product--card__icon--img">
                                                <span class="featured__product--card__icon">
                                                    <img class="display-block" src="/assets/img/icon/setting-icon.png" alt="setting-icon" />
                                                </span>
                                            </div>
                                            <div class="featured__product--card__content">
                                                <h2 class="featured__product--card__title h3">Products</h2>
                                                {/* <p class="featured__product--card__desc">Every team has a culture and set of ct
                                                    ations that have either
                                                    naturally evolved.</p> */}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div class="col-6 custom-col mb-30">
                                    {/* <article class="services__card">
                                        <Link class="services__card--link" to="/services">
                                            <div class="services__card--topbar d-flex justify-content-between">
                                                <div class="services__card--icon mb-20">
                                                    <img class="display-block services__card--primary__icon" src="/assets/img/icon/service-icon2.png" />
                                                    <img class="display-block services__card--secondary__icon" src="/assets/img/icon/service-icon2-white.png" />
                                                </div>
                                                <div class="services__card--number">
                                                    <span class="services__card--number__text">2</span>
                                                </div>
                                            </div>
                                            <div class="services__card--content">
                                                <h3 class="services__card--maintitle mb-15">Services</h3>
                                                <p class="services__card--desc mb-15">Every team has a culture and set of ct ations that have either been aed orsve naturally evolved.</p>
                                                <span class="services__card--readmore text__secondary"> Read More
                                                    <svg class="services__card--readmore__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                                                </span>
                                            </div>
                                        </Link>
                                    </article> */}
                                    <Link to="/services" className='card--link'>
                                        <div class="featured__product--card mb-30">
                                            <div class="featured__product--card__icon--img">
                                                <span class="featured__product--card__icon">
                                                    <img class="display-block" src="/assets/img/icon/setting-icon.png" alt="setting-icon" />
                                                </span>
                                            </div>
                                            <div class="featured__product--card__content">
                                                <h2 class="featured__product--card__title h3">Services</h2>
                                                {/* <p class="featured__product--card__desc">Every team has a culture and set of ct
                                                    ations that have either
                                                    naturally evolved.</p> */}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="project__section section--padding">
                    <div class="container">
                        <div class="section__heading text-center mb-20">
                            <h2 class="section__heading--maintitle text__secondary mb-10">Our Latest Products</h2>
                        </div>
                        <div class="shop__product--wrapper">
                            <div class="tab_content">
                                <div id="product_grid" class="tab_pane active show">
                                    <div class="product__section--inner product__grid--inner">
                                        <div class="row row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-2 mb--n30">
                                            {products.map(product => {
                                                return (
                                                    <div class="col custom-col-2 mb-30">
                                                        <ListContent product={product.product} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section class="blog__section blog__section--bg section--padding">
                    <div class="container">
                        <div class="section__heading text-center mb-50">
                            <h2 class="section__heading--maintitle text__secondary mb-10">Our Largest Blog</h2>
                            <p class="section__heading--desc">Beyond more stoic this along goodness this sed wow manatee mongos
                                flusterd impressive man farcrud opened.</p>
                        </div>
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={3}
                            speed={500}
                            centeredSlides={true}
                            // modules={[Autoplay]}
                            className='blog__section--inner blog__swiper--activation'
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            loop={true}

                        >
                            <SwiperSlide>
                                <article class="blog__card">
                                    <div class="blog__card--thumbnail">
                                        <a class="blog__card--thumbnail__link display-block" ><img class="blog__card--thumbnail__img display-block" src="/assets/img/blog/blog-post-2.jpeg" alt="blog-img" /></a>
                                    </div>
                                    <div class="blog__card--content">
                                        <ul class="blog__card--meta d-flex justify-content-center">
                                            <li class="blog__card--meta__text text__secondary">
                                                Ruso Jonsong
                                            </li>
                                            <li class="blog__card--meta__text">
                                                / Mar 02, 2022
                                            </li>
                                        </ul>
                                        <h3 class="blog__card--title"><a >Leading Research Award</a></h3>
                                        <a class="blog__card--btn primary__btn" >Read More </a>
                                    </div>
                                </article>
                            </SwiperSlide>
                            <SwiperSlide>
                                <article class="blog__card">
                                    <div class="blog__card--thumbnail">
                                        <a class="blog__card--thumbnail__link display-block" ><img class="blog__card--thumbnail__img display-block" src="/assets/img/blog/blog-post-2.jpeg" alt="blog-img" /></a>
                                    </div>
                                    <div class="blog__card--content">
                                        <ul class="blog__card--meta d-flex justify-content-center">
                                            <li class="blog__card--meta__text text__secondary">
                                                Ruso Jonsong
                                            </li>
                                            <li class="blog__card--meta__text">
                                                / Mar 02, 2022
                                            </li>
                                        </ul>
                                        <h3 class="blog__card--title"><a > Latest Product Launch </a></h3>
                                        <a class="blog__card--btn primary__btn" >Read More </a>
                                    </div>
                                </article>
                            </SwiperSlide>
                            <SwiperSlide>
                                <article class="blog__card">
                                    <div class="blog__card--thumbnail">
                                        <a class="blog__card--thumbnail__link display-block" ><img class="blog__card--thumbnail__img display-block" src="/assets/img/blog/blog-post-2.jpeg" alt="blog-img" /></a>
                                    </div>
                                    <div class="blog__card--content">
                                        <ul class="blog__card--meta d-flex justify-content-center">
                                            <li class="blog__card--meta__text text__secondary">
                                                Ruso Jonsong
                                            </li>
                                            <li class="blog__card--meta__text">
                                                / Mar 02, 2022
                                            </li>
                                        </ul>
                                        <h3 class="blog__card--title"><a >Ibuprofen Our Latest Addition </a></h3>
                                        <a class="blog__card--btn primary__btn" >Read More </a>
                                    </div>
                                </article>
                            </SwiperSlide>
                            <SwiperSlide>
                                <article class="blog__card">
                                    <div class="blog__card--thumbnail">
                                        <a class="blog__card--thumbnail__link display-block" ><img class="blog__card--thumbnail__img display-block" src="/assets/img/blog/blog-post-2.jpeg" alt="blog-img" /></a>
                                    </div>
                                    <div class="blog__card--content">
                                        <ul class="blog__card--meta d-flex justify-content-center">
                                            <li class="blog__card--meta__text text__secondary">
                                                Ruso Jonsong
                                            </li>
                                            <li class="blog__card--meta__text">
                                                / Mar 02, 2022
                                            </li>
                                        </ul>
                                        <h3 class="blog__card--title"><a >Huge Customer & Supplier Network</a></h3>
                                        <a class="blog__card--btn primary__btn" >Read More </a>
                                    </div>
                                </article>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </section> */}
            </main>
        </>
    )
}
