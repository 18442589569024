import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import BillingDetails from './BillingDetails'

export default function OrderingDetails({ deliveryAddress, setDeliveryAddress, setDeliveryMode, setPaymentMode, totalPrice, totalTax, placeOrder, isLoading, paymentMode, deliveryMode }) {
    const store = useSelector(state => state.storeFront.store)
    const { isLogin, currentUser } = useSelector(state => state.auth)
    const { storeDelivery } = store

    const [steps, setSteps] = useState(0)
    const [tab, setTab] = useState(0)

    const addNewAddress = (addressData) => {
        setDeliveryAddress(addressData)
        setSteps(1)
        setTab(1)
    }

    useEffect(() => {
        if (storeDelivery.paymentMode === 2) {
            setPaymentMode(2)
        }
        if (storeDelivery.deliveryMode === 2) {
            setDeliveryMode(2)
        }
    }, [store])

    if (!isLogin) {
        return (
            <div class="col-lg-8 col-md-6">
                <div class="message d-flex justify-content-between">
                    <p>Exisitng Customer? <Link to="/login">Click here to login</Link></p>
                    <p>New Customer? <Link to="/signup">Click here to signup</Link></p>
                </div>
            </div>
        )
    }

    return (
        <>
            <div class="col-lg-8 col-md-6">
                <div class="main checkout__mian">
                    <div class="accordion__container">
                        <div class={`accordion__items ${tab === 0 && "active"}`}>
                            <h2 class="accordion__items--title" onClick={() => setTab(0)}>
                                <button class="faq__accordion--btn accordion__items--button">Billing Details
                                    <span class="accordion__items--button__icon">
                                        <svg class="accordion__items--button__icon--svg" xmlns="http://www.w3.org/2000/svg" width="25.355" height="20.394" viewBox="0 0 512 512"><path d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z" fill="currentColor" /></svg>
                                    </span>
                                </button>
                            </h2>
                            <div class={tab !== 0 ? "accordion__items--body" : ""}>
                                {steps >= 0 && <BillingDetails setSteps={setSteps} setAddress={addNewAddress} />}
                            </div>
                        </div>
                        <div class={`accordion__items ${tab === 1 && "active"}`}>
                            <h2 class="accordion__items--title" onClick={() => setTab(1)}>
                                <button class="faq__accordion--btn accordion__items--button">Delivery Mode
                                    <span class="accordion__items--button__icon">
                                        <svg class="accordion__items--button__icon--svg" xmlns="http://www.w3.org/2000/svg" width="25.355" height="20.394" viewBox="0 0 512 512"><path d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z" fill="currentColor" /></svg>
                                    </span>
                                </button>
                            </h2>
                            <div class={tab !== 1 ? "accordion__items--body" : ""}>
                                {steps >= 1 ? <div class="step">
                                    <ul>
                                        {(storeDelivery.deliveryMode === 1 || storeDelivery.deliveryMode === 3) && <li>
                                            <label class="container_check">Home Delivery
                                                <input type="radio" name='delivery' checked={deliveryMode === 1} onChange={() => setDeliveryMode(1)} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </li>}
                                        {(storeDelivery.deliveryMode === 2 || storeDelivery.deliveryMode === 3) && <li>
                                            <label class="container_check">Self Pickup
                                                <input type="radio" name='delivery' checked={deliveryMode === 2} onChange={() => setDeliveryMode(2)} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </li>}
                                    </ul>
                                    {storeDelivery.deliveryMode > 0 ? <div class="text-end mb-20">
                                        <button class="primary__btn pull-right mb-20" onClick={() => { setSteps(2); setTab(2) }}>Continue</button>
                                    </div> : <p>Delivery mode has not been set by admin please contact admin</p>}
                                </div> : <p>Please Complete setps above to proceed</p>}
                            </div>
                        </div>
                        <div class={`accordion__items ${tab === 2 && "active"}`}>
                            <h2 class="accordion__items--title" onClick={() => setTab(2)}>
                                <button class="faq__accordion--btn accordion__items--button">Payment Mode
                                    <span class="accordion__items--button__icon">
                                        <svg class="accordion__items--button__icon--svg" xmlns="http://www.w3.org/2000/svg" width="25.355" height="20.394" viewBox="0 0 512 512"><path d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z" fill="currentColor" /></svg>
                                    </span>
                                </button>
                            </h2>
                            <div class={tab !== 2 ? "accordion__items--body" : ""}>
                                {steps >= 2 ? <div class="step">
                                    <ul>
                                        {(storeDelivery.paymentMode === 1 || storeDelivery.paymentMode === 3) && <li>
                                            <label class="container_check">COD
                                                <input type="radio" name='payment' checked={paymentMode === 1} onChange={() => setPaymentMode(1)} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </li>
                                        }
                                        {(storeDelivery.paymentMode === 2 || storeDelivery.paymentMode === 3) && <li>
                                            <label class="container_check">Online
                                                <input type="radio" name='payment' checked={paymentMode === 2} onChange={() => setPaymentMode(2)} />
                                                <span class="checkmark"></span>
                                            </label>
                                        </li>}
                                    </ul>
                                    {storeDelivery.paymentMode > 0 ? <div class="text-end mb-20">
                                        <button class="primary__btn pull-right mb-20" onClick={() => { setSteps(3); setTab(3) }}>Continue</button>
                                    </div> : <p>Payment mode has not been set by admin please contact admin</p>}
                                </div> : <p>Please Complete setps above to proceed</p>}
                            </div>
                        </div>
                    </div>
                    {steps === 3 &&
                        <div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6">
                                    <div class="row g-0 mb-2">
                                        <div xs="auto" class="text-muted me-3 col-xs-auto">
                                            Billing Address
                                        </div>
                                        <div xs="auto" class="sw-20 col-xs-auto">
                                            <span>
                                                {deliveryAddress}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6">
                                    <div class="text-end">
                                        <button class="primary__btn pull-right" onClick={placeOrder} disabled={isLoading}>Pay ₹ {totalPrice + totalTax}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
