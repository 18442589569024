import React from 'react'
import UomOption from './UomOption'

const SelectDropDown = ({ optionDetails = [], isRequired, option, handleInput }) => {
    const handleSelect = (e) => {
        const optionChanges = optionDetails.find(opt => opt.id === +e.target.value)
        if (!optionChanges) return
        handleInput(e.target.value, optionChanges, option)
    }
    return (
        <>
            <select class="contact__form--input" onChange={handleSelect} required={isRequired} >
                <option value="">Select</option>
                {optionDetails.map(details => {
                    if (details.isDeleted) return <></>
                    return <option key={details.id} value={details.id}>{details.optionValue}</option>
                })}
            </select>
            <div class="invalid-feedback">Please select pack size</div>
        </>
    )
}

export default function ProductOptions({ productOptions = [], selectedOption, setSelectedOption, uomId }) {

    const handleCheckbox = (value, optionDetails, options) => {
        const optionIndex = selectedOption.findIndex(option => option.optionId === options.id)
        const arrSelectedOption = [...selectedOption]
        if (optionIndex >= 0) {
            const checkedOptions = arrSelectedOption[optionIndex].optionDetails
            const changedOptionIndex = checkedOptions.findIndex(checked => checked.id === optionDetails.id)
            if (changedOptionIndex >= 0) {
                // checkedOptions[changedOptionIndex].isChecked = value
                checkedOptions.splice(changedOptionIndex, 1)
            } else {
                checkedOptions.push(optionDetails)
            }
            setSelectedOption(arrSelectedOption)
        } else {
            const data = {
                optionId: options.id,
                option: options,
                optionDetailsId: optionDetails.id,
                optionDetails: [optionDetails],
                value,
                type: 3
            }
            arrSelectedOption.push(data)
            setSelectedOption(arrSelectedOption)
        }
    }

    const handleOptions = (value, optionDetails, options) => {
        const optionIndex = selectedOption.findIndex(option => option.optionsId === options.id)
        const arrSelectedOption = JSON.parse(JSON.stringify(selectedOption))
        if (optionIndex >= 0) {
            arrSelectedOption[optionIndex].productOptionDetailsId = optionDetails.id
            arrSelectedOption[optionIndex].optionDetails = optionDetails
        } else {
            const data = {
                optionsId: options.id,
                option: options,
                productOptionDetailsId: optionDetails.id,
                optionDetails,
                value,
                type: 1
            }
            arrSelectedOption.push(data)
        }
        setSelectedOption(arrSelectedOption)
    }

    const handleUom = (value, optionDetails, options) => {
        const optionIndex = selectedOption.findIndex(option => option.type === 2)
        const arrSelectedOption = [...selectedOption]
        if (optionIndex >= 0) {
            arrSelectedOption[optionIndex].optionDetails = optionDetails
            arrSelectedOption[optionIndex].productOptionDetailsId = null
            arrSelectedOption[optionIndex].value = value
        } else {
            const data = {
                optionsId: options.id,
                // option: options,
                productOptionDetailsId: null,
                optionDetails,
                value,
                type: 2
            }
            arrSelectedOption.push(data)
        }
        setSelectedOption(arrSelectedOption)
    }

    return (
        <>
            {productOptions.map(option => {
                if (option.isDeleted) return <></>
                if (option.options?.type === 1 || option.options?.type === 2 || option.options?.type === 3) {
                    return (
                        <>
                            {/* <select class="form-control" onChange={(e) => handleOptions(e.target.value, details, option.options)} >
                                <option value="">Select</option>
                                {option.productOptionDetails.map(details => {
                                    return (
                                        <>
                                             {details.map(options => { */}
                            {/* <option key={details.id} value={details.id}>{details.optionValue}</option> */}
                            {/* })} */}
                            {/* <input height="50px" type="radio" class="form-control" name="size" id={details.id} value={details.id} onChange={(e) => handleOptions(e.target.value, details, option.options)} required={option.isRequired} />
                                        <label class="btn btn-default" htmlFor={details.id}>
                                        {details.optionValue}
                                    </label>
                                        </>
                                    )
                                })}
                            </select> */}
                            <SelectDropDown key={option.id} optionDetails={option.productOptionDetails} isRequired={!option.isRequired} option={option.options} handleInput={handleOptions} />
                        </>
                    )
                }

                if (option.options?.type === 10) return <UomOption option={option.options} handleChange={handleUom} id={uomId} isRequired={option.isRequired} />
            }
            )}


        </>
    )
}
