import React from 'react'

export default function ItemCounter({ quantity, minQuantity, ind, increase, decrease }) {

    const spinUp = () => {
        increase(ind)
    };

    const spinDown = () => {
        if (quantity > minQuantity) {
            decrease(ind)
        }
        // setValue(parseInt(typeof value === 'number' ? value : 0, 10) - 1);
    };

    return (
        <>
            <div class="product__variant--list quantity d-flex align-items-center mb-20">
                <div class="quantity__box">
                    <button type="button" class="quantity__value quickview__value--quantity decrease" aria-label="quantity value" value="Decrease Value" onClick={spinDown}>-</button>
                    <label>
                        <input type="number" class="quantity__number quickview__value--number" value={quantity} />
                    </label>
                    <button type="button" class="quantity__value quickview__value--quantity increase" aria-label="quantity value" value="Increase Value" onClick={spinUp}>+</button>
                </div>
            </div>
        </>
    )
}
