import React from 'react'

export default function Modal({ modal, setModal, children, closeBtn }) {
    return (
        <div className={`modal ${modal ? 'is-visible' : ''}`}>
            <div className={`newsletter__popup ${modal ? "newsletter__show" : ""}`} data-animation="slideInUp">
                <div id="boxes" className="newsletter__popup--inner">
                    {closeBtn && <button className="newsletter__popup--close__btn" aria-label="search close button" onClick={() => setModal(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 512 512"><path fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"></path></svg>
                    </button>}
                    {children}
                </div>
            </div>
        </div>
    )
}
