import React, { useState } from 'react'
import axios from '../../assets/axios/axios'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import StoresApi from '../../assets/api/StoreApis'
import { webBaseUrl } from '../../assets/axios/axios'
import InformationModal from '../../components/InformationModal'

export default function Footer() {
    const store = useSelector(state => state.storeFront.store)

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [storeInformation, setStoreInformation] = useState({})


    const getPrivacyPolicy = () => {
        axios.get(`${StoresApi.StoreInformation}/GetPrivacyPolicyByStoreId/${store.id}`)
            .then(response => {
                setStoreInformation(response.data.data)
                setIsOpenModal(true)
            })
            .catch(error => {
                console.log(error.response);
                toast.error("Information not found, please Contact Admin", {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                })
            })
    }

    const getTermsConditions = () => {
        axios.get(`${StoresApi.StoreInformation}/GetTermsConditionByStoreId/${store.id}`)
            .then(response => {
                setStoreInformation(response.data.data)
                setIsOpenModal(true)
            })
            .catch(error => {
                console.log(error);
                toast.error("Information not found, please Contact Admin", {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                })
            })
    }

    const getAboutUs = () => {
        axios.get(`${StoresApi.StoreInformation}/GetAboutUsByStoreId/${store.id}`)
            .then(response => {
                setStoreInformation(response.data.data)
                setIsOpenModal(true)
            })
            .catch(error => {
                console.log(error);
                toast.error("Information not found, please Contact Admin", {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                })
            })
    }


    return (
        <>
            <footer class="footer__section footer__bg">
                <div class="container">
                    <div class="main__footer section--padding">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="footer__widget">
                                    <h2 class="footer__widget--title d-none d-md-block">About Us <button class="footer__widget--button" aria-label="footer widget button"></button>
                                        <svg class="footer__widget--title__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394" viewBox="0 0 10.355 6.394">
                                            <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                                        </svg>
                                    </h2>
                                    <div class="footer__widget--inner">
                                        <a class="footer__logo"><img class="footer__logo--img display-block" src={store.path ? `${webBaseUrl}${store.path}` : "assets/img/logo/logo-default.png"} alt="footer-logo" /></a>
                                        <p class="footer__widget--desc">PADM has state of art laboratory approved by state and central regulatory body. It has all the modern day analytical instruments to fulfill customers every need.</p>
                                        <ul class="footer__contact--info">
                                            <li class="footer__contact--info__list">
                                                <svg class="footer__contact--info__icon" xmlns="http://www.w3.org/2000/svg" width="15.797" height="20.05" viewBox="0 0 512 512"><path d="M451 374c-15.88-16-54.34-39.35-73-48.76-24.3-12.24-26.3-13.24-45.4.95-12.74 9.47-21.21 17.93-36.12 14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48 5.41-23.23 14.79-36c13.22-18 12.22-21 .92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9 44 119.9 47 108.83 51.6A160.15 160.15 0 0083 65.37C67 76 58.12 84.83 51.91 98.1s-9 44.38 23.07 102.64 54.57 88.05 101.14 134.49S258.5 406.64 310.85 436c64.76 36.27 89.6 29.2 102.91 23s22.18-15 32.83-31a159.09 159.09 0 0013.8-25.8C465 391.17 468 391.17 451 374z" fill="currentColor" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /></svg>
                                                <a href={`tel:${store.storeContact}`}>{store.storeContact}</a>
                                            </li>
                                            <li class="footer__contact--info__list">
                                                <svg class="footer__contact--info__icon" xmlns="http://www.w3.org/2000/svg" width="20.57" height="13.13" viewBox="0 0 31.57 31.13">
                                                    <path d="M30.413,4H5.157C3.421,4,2.016,5.751,2.016,7.891L2,31.239c0,2.14,1.421,3.891,3.157,3.891H30.413c1.736,0,3.157-1.751,3.157-3.891V7.891C33.57,5.751,32.149,4,30.413,4Zm0,7.783L17.785,21.511,5.157,11.783V7.891l12.628,9.728L30.413,7.891Z" transform="translate(-2 -4)" fill="currentColor"></path>
                                                </svg>
                                                <a href={`mailto:${store.storeDelivery?.email}`}>{store.storeDelivery?.email}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="footer__widget">
                                    <h2 class="footer__widget--title ">Recent Post <button class="footer__widget--button" aria-label="footer widget button"></button>
                                        <svg class="footer__widget--title__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394" viewBox="0 0 10.355 6.394">
                                            <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                                        </svg>
                                    </h2>
                                    <ul class="footer__widget--menu footer__widget--inner">
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/about">About Us</Link></li>
                                        {/* <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="wishlist.html">Wishlist</Link></li> */}
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/contact">Contact Us</Link></li>
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/qualityPolicy">Quality Policy</Link></li>
                                        {/* <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="privacy-policy.html">Privacy Policy</Link></li> */}
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/approvals">Approvals</Link></li>
                                        {/* <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/faqs">Frequently</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="footer__widget">
                                    <h2 class="footer__widget--title ">Account Info <button class="footer__widget--button" aria-label="footer widget button"></button>
                                        <svg class="footer__widget--title__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394" viewBox="0 0 10.355 6.394">
                                            <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                                        </svg>
                                    </h2>
                                    <ul class="footer__widget--menu footer__widget--inner">
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/profile">My Account</Link></li>
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/cart">Shopping Cart</Link></li>
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/login">Login</Link></li>
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/signup">Register</Link></li>
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/career">Career Options</Link></li>
                                        {/* <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="checkout.html">Checkout</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-6">
                                <div class="footer__widget">
                                    <h2 class="footer__widget--title ">Social share <button class="footer__widget--button" aria-label="footer widget button"></button>
                                        <svg class="footer__widget--title__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394" viewBox="0 0 10.355 6.394">
                                            <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                                        </svg>
                                    </h2>
                                    <div class="footer__widget--inner">
                                        <ul class="footer__social--style4">
                                            <li class="footer__social--list">
                                                <Link class="footer__social--list__icon" target="_blank" to="https://www.facebook.com">
                                                    <span class="footer__social--icon__svg">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="8.667" height="18.524" viewBox="0 0 7.667 16.524">
                                                            <path data-name="Path 237" d="M967.495,353.678h-2.3v8.253h-3.437v-8.253H960.13V350.77h1.624v-1.888a4.087,4.087,0,0,1,.264-1.492,2.9,2.9,0,0,1,1.039-1.379,3.626,3.626,0,0,1,2.153-.6l2.549.019v2.833h-1.851a.732.732,0,0,0-.472.151.8.8,0,0,0-.246.642v1.719H967.8Z" transform="translate(-960.13 -345.407)" fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                    <span class="footer__social--title">Facebook</span>
                                                </Link>
                                            </li>
                                            <li class="footer__social--list">
                                                <Link class="footer__social--list__icon" target="_blank" to="https://twitter.com">
                                                    <span class="footer__social--icon__svg">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.489" height="15.384" viewBox="0 0 16.489 13.384">
                                                            <path data-name="Path 303" d="M966.025,1144.2v.433a9.783,9.783,0,0,1-.621,3.388,10.1,10.1,0,0,1-1.845,3.087,9.153,9.153,0,0,1-3.012,2.259,9.825,9.825,0,0,1-4.122.866,9.632,9.632,0,0,1-2.748-.4,9.346,9.346,0,0,1-2.447-1.11q.4.038.809.038a6.723,6.723,0,0,0,2.24-.376,7.022,7.022,0,0,0,1.958-1.054,3.379,3.379,0,0,1-1.958-.687,3.259,3.259,0,0,1-1.186-1.666,3.364,3.364,0,0,0,.621.056,3.488,3.488,0,0,0,.885-.113,3.267,3.267,0,0,1-1.374-.631,3.356,3.356,0,0,1-.969-1.186,3.524,3.524,0,0,1-.367-1.5v-.057a3.172,3.172,0,0,0,1.544.433,3.407,3.407,0,0,1-1.1-1.214,3.308,3.308,0,0,1-.4-1.609,3.362,3.362,0,0,1,.452-1.694,9.652,9.652,0,0,0,6.964,3.538,3.911,3.911,0,0,1-.075-.772,3.293,3.293,0,0,1,.452-1.694,3.409,3.409,0,0,1,1.233-1.233,3.257,3.257,0,0,1,1.685-.461,3.351,3.351,0,0,1,2.466,1.073,6.572,6.572,0,0,0,2.146-.828,3.272,3.272,0,0,1-.574,1.083,3.477,3.477,0,0,1-.913.8,6.869,6.869,0,0,0,1.958-.546A7.074,7.074,0,0,1,966.025,1144.2Z" transform="translate(-951.23 -1140.849)" fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                    <span class="footer__social--title">Twitter</span>
                                                </Link>
                                            </li>
                                            <li class="footer__social--list">
                                                <Link class="footer__social--list__icon" target="_blank" to="https://www.instagram.com">
                                                    <span class="footer__social--icon__svg">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.497" height="18.492" viewBox="0 0 19.497 19.492">
                                                            <path data-name="Icon awesome-instagram" d="M9.747,6.24a5,5,0,1,0,5,5A4.99,4.99,0,0,0,9.747,6.24Zm0,8.247A3.249,3.249,0,1,1,13,11.238a3.255,3.255,0,0,1-3.249,3.249Zm6.368-8.451A1.166,1.166,0,1,1,14.949,4.87,1.163,1.163,0,0,1,16.115,6.036Zm3.31,1.183A5.769,5.769,0,0,0,17.85,3.135,5.807,5.807,0,0,0,13.766,1.56c-1.609-.091-6.433-.091-8.042,0A5.8,5.8,0,0,0,1.64,3.13,5.788,5.788,0,0,0,.065,7.215c-.091,1.609-.091,6.433,0,8.042A5.769,5.769,0,0,0,1.64,19.341a5.814,5.814,0,0,0,4.084,1.575c1.609.091,6.433.091,8.042,0a5.769,5.769,0,0,0,4.084-1.575,5.807,5.807,0,0,0,1.575-4.084c.091-1.609.091-6.429,0-8.038Zm-2.079,9.765a3.289,3.289,0,0,1-1.853,1.853c-1.283.509-4.328.391-5.746.391S5.28,19.341,4,18.837a3.289,3.289,0,0,1-1.853-1.853c-.509-1.283-.391-4.328-.391-5.746s-.113-4.467.391-5.746A3.289,3.289,0,0,1,4,3.639c1.283-.509,4.328-.391,5.746-.391s4.467-.113,5.746.391a3.289,3.289,0,0,1,1.853,1.853c.509,1.283.391,4.328.391,5.746S17.855,15.705,17.346,16.984Z" transform="translate(0.004 -1.492)" fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                    <span class="footer__social--title">Instagram</span>
                                                </Link>
                                            </li>
                                            <li class="footer__social--list">
                                                <Link class="footer__social--list__icon" target="_blank" to="https://www.linkedin.com">
                                                    <span class="footer__social--icon__svg">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.419" height="14.419" viewBox="0 0 19.419 19.419">
                                                            <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M4.347,19.419H.321V6.454H4.347ZM2.332,4.686A2.343,2.343,0,1,1,4.663,2.332,2.351,2.351,0,0,1,2.332,4.686ZM19.415,19.419H15.4V13.108c0-1.5-.03-3.433-2.093-3.433-2.093,0-2.414,1.634-2.414,3.325v6.42H6.869V6.454H10.73V8.223h.056A4.23,4.23,0,0,1,14.6,6.129c4.075,0,4.824,2.683,4.824,6.168v7.122Z" fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                    <span class="footer__social--title">Linkedin</span>
                                                </Link>
                                            </li>
                                            <li class="footer__social--list">
                                                <Link class="footer__social--list__icon" target="_blank" to="https://www.youtube.com">
                                                    <span class="footer__social--icon__svg">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.49" height="13.582" viewBox="0 0 16.49 11.582">
                                                            <path data-name="Path 321" d="M967.759,1365.592q0,1.377-.019,1.717-.076,1.114-.151,1.622a3.981,3.981,0,0,1-.245.925,1.847,1.847,0,0,1-.453.717,2.171,2.171,0,0,1-1.151.6q-3.585.265-7.641.189-2.377-.038-3.387-.085a11.337,11.337,0,0,1-1.5-.142,2.206,2.206,0,0,1-1.113-.585,2.562,2.562,0,0,1-.528-1.037,3.523,3.523,0,0,1-.141-.585c-.032-.2-.06-.5-.085-.906a38.894,38.894,0,0,1,0-4.867l.113-.925a4.382,4.382,0,0,1,.208-.906,2.069,2.069,0,0,1,.491-.755,2.409,2.409,0,0,1,1.113-.566,19.2,19.2,0,0,1,2.292-.151q1.82-.056,3.953-.056t3.952.066q1.821.067,2.311.142a2.3,2.3,0,0,1,.726.283,1.865,1.865,0,0,1,.557.49,3.425,3.425,0,0,1,.434,1.019,5.72,5.72,0,0,1,.189,1.075q0,.095.057,1C967.752,1364.1,967.759,1364.677,967.759,1365.592Zm-7.6.925q1.49-.754,2.113-1.094l-4.434-2.339v4.66Q958.609,1367.311,960.156,1366.517Z" transform="translate(-951.269 -1359.8)" fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                    <span class="footer__social--title">Youtube</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer__bottom d-flex justify-content-between align-items-center">
                        <p class="copyright__content  m-0">Copyright © 2022 <Link class="copyright__content--link" to="/"> {store.name}</Link> . All Rights Reserved.</p>
                        <p class="footer__bottom--desc"><Link onClick={getTermsConditions}>Term & condition</Link> , <Link onClick={getPrivacyPolicy}>Privacy Policy</Link></p>
                    </div>
                </div>
            </footer>
            <InformationModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} title={storeInformation.informationType} content={storeInformation.informationDetails} />
        </>
    )
}
