import React, { useEffect, useState } from 'react'
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

export default function Gallery() {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const openLightbox = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    const urls = [
        { src: "/assets/img/gallery/FDS_1692.jpg" },
        { src: "/assets/img/gallery/FDS_1711.jpg" },
        { src: "/assets/img/gallery/FDS_3632.jpg" },
        { src: "/assets/img/gallery/FDS_3661.jpg" },
        { src: "/assets/img/gallery/FDS_3689.jpg" },
        { src: "/assets/img/gallery/FDS_3698.jpg" },
        { src: "/assets/img/gallery/FDS_3714.jpg" },
        { src: "/assets/img/gallery/FDS_3728.jpg" },
        { src: "/assets/img/gallery/FDS_3738.jpg" },
        { src: "/assets/img/gallery/FDS_3784.jpg" },
        { src: "/assets/img/gallery/FDS_3829.jpg" },
        { src: "/assets/img/gallery/SSS_2474.jpg" },
        { src: "/assets/img/gallery/SSS_2518.jpg" },
        { src: "/assets/img/gallery/SSS_2539.jpg" },
    ]

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div class="container-fluid">
                <div class="related__post--area mb-50">
                    <div class="related__post--heading text-center mb-30">
                        <h2 class="related__post--heading__maintitle h3">Gallery</h2>
                    </div>
                    {/* <div class="row row-cols-md-4 row-cols-sm-4 row-cols-2 mb--n28"> */}
                    <LightGallery plugins={[lgThumbnail, lgZoom]} elementClassNames="row row-cols-md-4 row-cols-sm-4 row-cols-2 mb--n28">
                        {urls.map((img, ind) => {
                            return (
                                <a class="col custom-col mb-28 gallery-item" data-src={img.src}>
                                    <div class="related__post--items">
                                        <div class="related__post--thumbnail border-radius-10 mb-20">
                                            <a class="display-block" data-src={img.src}><img class="related__post--img display-block border-radius-10" src={img.src} /></a>
                                        </div>
                                    </div>
                                </a>
                            )
                        })}
                    </LightGallery>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}
