import React, { useEffect, useRef, useState } from 'react'
import axios, { webBaseUrl } from '../../assets/axios/axios'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import StoresApi from '../../assets/api/StoreApis'
import { updateUser } from '../../redux/slices/authSlice'

export default function UserDetails({ setTab }) {

    const dispatch = useDispatch()
    const detailsRef = useRef(null)
    const imageRef = useRef(null)

    const user = useSelector(state => state.auth.currentUser)
    const { customers } = user
    const store = useSelector(state => state.storeFront.store)

    const [userDetails, setUserDetils] = useState({})
    const [validate, setValidate] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const getData = () => {
        axios.get(`${StoresApi.CustomersUrl}/GetCustomer/${user.customersId}`, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        })
            .then(response => {

                setUserDetils(response.data.data)
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    const handleInput = (e) => {
        setUserDetils({ ...userDetails, [e.target.name]: e.target.value })
    }

    const updateProfile = (file = "") => {
        const formData = new FormData()
        formData.append("id", userDetails.id)
        formData.append("storeId", userDetails.storeId)
        formData.append("name", userDetails.name)
        formData.append("email", userDetails.email)
        formData.append("mobileNumber", userDetails.mobileNumber)
        formData.append("gstin", userDetails.gstin)
        formData.append("organization", userDetails.organization)
        formData.append("status", userDetails.status)
        formData.append("file", file)
        axios.put(`${StoresApi.CustomersUrl}/UpdateCustomer`, formData, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        })
            .then(response => {
                dispatch(updateUser(response.data.data))
                setUserDetils(response.data.data)
                setValidate(false)
                setIsLoading(false)
                toast.success("Profile updated successfully", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000
                })
            })
            .catch(error => {
                console.log(error.response);
                setIsLoading(false)
            })
    }

    const handleImage = (e) => {
        const File = e.target.files
        const arrValidExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        const Size = Math.round(File[0].size / (1024 * 1024));// Max 2 MB
        if (File.length > 0) {
            if (!arrValidExtensions.includes(File[0].name.toLowerCase().split('.').reverse()[0])) {
                toast.error("Invalid file type.", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000
                })
                e.target.value = null
            } else if (Size > 2) {
                toast.error("File size should be less than or equal to 2 MB.", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    hideProgressBar: true,
                    autoClose: 3000
                })
                e.target.value = null
            }
            else {
                updateProfile(e.target.files[0])
            }
        }
    }

    const submit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("Validation fails");
        } else {
            setIsLoading(true)
            updateProfile()
        }
        setValidate(true)
    }

    const close = () => {
        setValidate(false)
    }

    return (
        <>
            <div class="testimonial__card--thumbnail">
                <img class="testimonial__card--thumbnail__img display-block border-radius-20" width="100" src={userDetails.path ? `${webBaseUrl}${userDetails.path}` : "/assets/img/other/avatar.jpg"} alt="user-img" onClick={() => imageRef.current.click()} />
                <input type="file" onChange={handleImage} style={{ display: "none" }} ref={imageRef} />
            </div>
            <Form noValidate onSubmit={submit} validated={validate} className="contact__form--inner">
                <div className="row mb-5">
                    <div className="col-lg-6 col-md-6">
                        <div className="contact__form--list mb-20">
                            <label className="contact__form--label">Name <span className="contact__form--label__star">*</span></label>
                            <input className="contact__form--input" required type="text" name='name' value={userDetails.name} onChange={handleInput} />
                            <div class="invalid-feedback">please enter name</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="contact__form--list mb-20">
                            <label className="contact__form--label">Email <span className="contact__form--label__star">*</span></label>
                            <input className="contact__form--input" required type="email" name='email' value={userDetails.email} onChange={handleInput} />
                            <div class="invalid-feedback">please enter name</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="contact__form--list mb-20">
                            <label className="contact__form--label">Mobile Number <span className="contact__form--label__star">*</span></label>
                            <input className="contact__form--input" required type="tel" name='mobileNumber' value={userDetails.mobileNumber} onChange={handleInput} />
                            <div class="invalid-feedback">please enter name</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="contact__form--list mb-20">
                            <label className="contact__form--label">Organization<span className="contact__form--label__star">*</span></label>
                            <input className="contact__form--input" required type="text" name='organization' value={userDetails.organization} onChange={handleInput} />
                            <div class="invalid-feedback">please enter name</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="contact__form--list mb-20">
                            <label className="contact__form--label">GST No<span className="contact__form--label__star">*</span></label>
                            <input className="contact__form--input" required type="text" name='gstin' value={userDetails.gstin} onChange={handleInput} />
                            <div class="invalid-feedback">please enter name</div>
                        </div>
                    </div>
                </div>
                {/* <button className="recharge__btn primary__btn" type="button" onClick={close}>Cancel</button> */}
                <button className="recharge__btn pull-right primary__btn" type="submit">Submit</button>
            </Form>
        </>
    )
}
