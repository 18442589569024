import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import UserDetails from './UserDetails';
import Orders from './orders/Orders';
import Security from './Security';
import Addresses from './Addresses';
import { logoutCurrentUser } from '../../redux/slices/authSlice';

export default function Profile() {
    const { currentUser, isLogin } = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [tab, setTab] = useState(0)

    const logout = () => {
        dispatch(logoutCurrentUser())
        navigate('/login', { replace: true })
    }

    return (
        <>
            <section class="my__account--section section--padding">
                <div class="container">
                    <p class="account__welcome--text">Hello, {currentUser.name} welcome to your dashboard!</p>
                    <div class="my__account--section__inner border-radius-10 d-flex">
                        <div class="account__left--sidebar">
                            <h2 class={`account__content--title h3 mb-20 ${tab === 0 ? "active" : ""}`}><Link to='#' onClick={() => setTab(0)}>My Profile</Link></h2>
                            <ul class="account__menu">
                                <li class={`account__menu--list ${tab === 1 ? 'active' : ''}`}><Link to='#' onClick={() => setTab(1)}>Orders</Link></li>
                                <li class={`account__menu--list ${tab === 2 ? 'active' : ''}`}><Link to='#' onClick={() => setTab(2)}>Security</Link></li>
                                <li class={`account__menu--list ${tab === 3 ? 'active' : ''}`}><Link to='#' onClick={() => setTab(3)}>Addresses</Link></li>
                                <li class="account__menu--list" onClick={logout}><Link to='#' >Log Out</Link></li>
                            </ul>
                        </div>
                        <div class="account__wrapper">
                            <div class="account__content">
                                {tab === 0 && <UserDetails setTab={setTab} />}
                                {tab === 1 && <Orders setProfileTab={setTab} />}
                                {tab === 2 && <Security setTab={setTab} />}
                                {tab === 3 && <Addresses setTab={setTab} />}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
