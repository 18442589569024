import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function ContactUs() {
    const { store } = useSelector(state => state.storeFront)
    const { storeDelivery } = store

    const [validate, setValidate] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("Validation fails");
        } else {
            console.log("validated");
        }
        setValidate(true)
    }

    return (
        <>
            <main class="main__content_wrapper">
                <section class="contact__section section--padding">
                    <div class="container">
                        <div class="section__heading text-center mb-40">
                            <h2 class="section__heading--maintitle text__secondary mb-10">Get In Touch</h2>
                            <p class="section__heading--desc">Beyond more stoic this along goodness this sed wow manatee mongos
                                flusterd impressive man farcrud opened.</p>
                        </div>
                        <div class="main__contact--area">
                            <div class="row align-items-center row-md-reverse">
                                <div class="col-lg-5">
                                    <div class="contact__info border-radius-10">
                                        <div class="contact__info--items">
                                            <h3 class="contact__info--content__title text-white mb-15">Contact Us</h3>
                                            <div class="contact__info--items__inner d-flex">
                                                <div class="contact__info--icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="31.568" height="31.128" viewBox="0 0 31.568 31.128">
                                                        <path id="ic_phone_forwarded_24px" d="M26.676,16.564l7.892-7.782L26.676,1V5.669H20.362v6.226h6.314Zm3.157,7a18.162,18.162,0,0,1-5.635-.887,1.627,1.627,0,0,0-1.61.374l-3.472,3.424a23.585,23.585,0,0,1-10.4-10.257l3.472-3.44a1.48,1.48,0,0,0,.395-1.556,17.457,17.457,0,0,1-.9-5.556A1.572,1.572,0,0,0,10.1,4.113H4.578A1.572,1.572,0,0,0,3,5.669,26.645,26.645,0,0,0,29.832,32.128a1.572,1.572,0,0,0,1.578-1.556V25.124A1.572,1.572,0,0,0,29.832,23.568Z" transform="translate(-3 -1)" fill="currentColor" />
                                                    </svg>
                                                </div>
                                                <div class="contact__info--content">
                                                    <p class="contact__info--content__desc text-white">Change the design through a range <br />
                                                        <a href={`tel:${store.storeContact}`}>{store.storeContact}</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="contact__info--items">
                                            <h3 class="contact__info--content__title text-white mb-15">Email Address</h3>
                                            <div class="contact__info--items__inner d-flex">
                                                <div class="contact__info--icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="31.57" height="31.13" viewBox="0 0 31.57 31.13">
                                                        <path id="ic_email_24px" d="M30.413,4H5.157C3.421,4,2.016,5.751,2.016,7.891L2,31.239c0,2.14,1.421,3.891,3.157,3.891H30.413c1.736,0,3.157-1.751,3.157-3.891V7.891C33.57,5.751,32.149,4,30.413,4Zm0,7.783L17.785,21.511,5.157,11.783V7.891l12.628,9.728L30.413,7.891Z" transform="translate(-2 -4)" fill="currentColor" />
                                                    </svg>
                                                </div>
                                                <div class="contact__info--content">
                                                    <p class="contact__info--content__desc text-white">
                                                        <a href={`mailto:${store.storeDelivery?.email}`}>{store.storeDelivery?.email}</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="contact__info--items">
                                            <h3 class="contact__info--content__title text-white mb-15">Office Location</h3>
                                            <div class="contact__info--items__inner d-flex">
                                                <div class="contact__info--icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="31.57" height="31.13" viewBox="0 0 31.57 31.13">
                                                        <path id="ic_account_balance_24px" d="M5.323,14.341V24.718h4.985V14.341Zm9.969,0V24.718h4.985V14.341ZM2,32.13H33.57V27.683H2ZM25.262,14.341V24.718h4.985V14.341ZM17.785,1,2,8.412v2.965H33.57V8.412Z" transform="translate(-2 -1)" fill="currentColor" />
                                                    </svg>
                                                </div>
                                                <div class="contact__info--content">
                                                    <p class="contact__info--content__desc text-white">  {storeDelivery.storeAddress}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="contact__form">
                                        <Form noValidate validated={validate} onSubmit={handleSubmit} class="contact__form--inner">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input1">First Name <span class="contact__form--label__star">*</span></label>
                                                        <input class="contact__form--input" name="firstname" id="input1" placeholder="Your First Name" type="text" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input2">Last Name <span class="contact__form--label__star">*</span></label>
                                                        <input class="contact__form--input" name="lastname" id="input2" placeholder="Your Last Name" type="text" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input3">Phone Number <span class="contact__form--label__star">*</span></label>
                                                        <input class="contact__form--input" name="number" id="input3" placeholder="Phone number" type="text" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input4">Email <span class="contact__form--label__star">*</span></label>
                                                        <input class="contact__form--input" name="email" id="input4" placeholder="Email" type="text" />
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="contact__form--list mb-10">
                                                        <label class="contact__form--label" for="input5">Write Your Message <span class="contact__form--label__star">*</span></label>
                                                        <textarea class="contact__form--textarea" name="message" id="input5" placeholder="Write Your Message"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="account__login--remember position__relative mb-15">
                                                <input class="checkout__checkbox--input" id="check2" type="checkbox" />
                                                <span class="checkout__checkbox--checkmark"></span>
                                                <label class="checkout__checkbox--label login__remember--label" for="check2">
                                                    Accept Terms & Condition</label>
                                            </div>
                                            <button class="contact__form--btn primary__btn" type="submit">Submit Now</button>
                                            <p class="form-messege"></p>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="contact__map--area section--padding pt-0">
                    <iframe class="contact__map--iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.37479446846!2d77.50200571474073!3d13.011788290829568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d082d287375%3A0x693765691a05e0c8!2sPADM%20Laboratories%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1675690126073!5m2!1sen!2sin" style={{ border: "0" }} allowfullscreen="" loading="lazy"></iframe>
                </div>
            </main>
        </>
    )
}
