import React, { useState } from 'react'
import axios from '../../assets/axios/axios'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import StoresApi from '../../assets/api/StoreApis'

export default function Security() {
    const user = useSelector(state => state.auth.currentUser)

    const [validated, setValidated] = useState(false)
    const [password, setPassword] = useState({
        password: '',
        confirm: ''
    })

    const submitHandler = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("validity fails");
        } else {
            if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,12}$/.test(password.password)) {
                const msg = "Password must contain minimum eight characters, maximum 12 characters, at least one upppercase letter, at least one lowercase letter and one number and one special character."
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                });
            } else if (password.password != password.confirm) {
                toast.error("Password did not match: Please try again.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                });
            } else {
                updatePassword()
            }
        }
        setValidated(true)
    }

    const close = () => {
        setPassword({
            password: '',
            confirm: ''
        })
        setValidated(false)
    }

    const updatePassword = async () => {
        const data = {
            id: user.customersId,
            email: user.email,
            password: password.confirm
        }
        console.log(data);
        try {
            const response = await axios.put(`${StoresApi.CustomerUsersAuthUrl}/UpdatePassword`, data)
            toast.success('Password updated succesfully', {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
                autoClose: 3000
            })
            close()
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <Form noValidate onSubmit={submitHandler} validated={validated} className="contact__form--inner">
                <div className="row mb-5">
                    <div className="col-lg-6 col-md-6">
                        <div className="contact__form--list mb-20">
                            <label className="contact__form--label" for="input1">Password <span className="contact__form--label__star">*</span></label>
                            <input className="contact__form--input" required placeholder="*****" type="password" name='accountHolder' value={password.password} onChange={(e) => setPassword({ ...password, password: e.target.value })} />
                            <div class="invalid-feedback">please enter password</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="contact__form--list mb-20">
                            <label className="contact__form--label" for="input1">Retype Password <span className="contact__form--label__star">*</span></label>
                            <input className="contact__form--input" required placeholder="*****" type="password" name='accountHolder' value={password.confirm} onChange={(e) => setPassword({ ...password, confirm: e.target.value })} />
                            <div class="invalid-feedback">please re enter password</div>
                        </div>
                    </div>
                </div>
                <button className="recharge__btn primary__btn" type="button" onClick={close}>Cancel</button>
                <button className="recharge__btn pull-right primary__btn" type="submit">Submit</button>
            </Form>
        </>
    )
}
