import States from '../assets/States';
import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import Modal from './Modal';

export function CustomerAddressFrom({ details, handleInput }) {
    return (
        <>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 mb-20">
                    <div class="checkout__input--list ">
                        <label class="checkout__input--label mb-5" for="input1">Full Name <span class="checkout__input--label__star">*</span></label>
                        <input class="checkout__input--field border-radius-5" placeholder="First name" type="text" name="name" required value={details.name} onChange={handleInput} />
                        <div class="invalid-feedback">Please enter first name</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-20">
                    <div class="checkout__input--list ">
                        <label class="checkout__input--label mb-5" for="input1">Mobile <span class="checkout__input--label__star">*</span></label>
                        <input class="checkout__input--field border-radius-5" placeholder="Mobile" type="text" name="mobileNumber" required value={details.mobileNumber} onChange={handleInput} />
                        <div class="invalid-feedback">Please enter valid mobile number</div>
                    </div>
                </div>
                {/* <div class="col-lg-6 col-md-6 col-sm-6 mb-20">
                    <div class="checkout__input--list ">
                        <label class="checkout__input--label mb-5" for="input1">Last Name <span class="checkout__input--label__star">*</span></label>
                        <input class="checkout__input--field border-radius-5" placeholder="Last name" type="text" name="name" />
                        <div class="invalid-feedback">Please enter last name</div>
                    </div>
                </div> */}
            </div>
            {/* <div class="row">
                 <div class="col-lg-6 col-md-6 col-sm-6 mb-20">
                    <div class="checkout__input--list ">
                        <label class="checkout__input--label mb-5" for="input1">Email<span class="checkout__input--label__star">*</span></label>
                        <input class="checkout__input--field border-radius-5" placeholder="Email" type="email" name="email" required value={details.email} onChange={handleInput} />
                        <div class="invalid-feedback">Please enter valid email</div>
                    </div>
                </div>
            </div> */}
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 mb-20">
                    <div class="checkout__input--list ">
                        <label class="checkout__input--label mb-5" for="input1">Address Line 1<span class="checkout__input--label__star">*</span></label>
                        <input class="checkout__input--field border-radius-5" placeholder="Address Line 1" type="text" name="addressLine1" required value={details.addressLine1} onChange={handleInput} />
                        <div class="invalid-feedback">Please enter address line 1</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-20">
                    <div class="checkout__input--list ">
                        <label class="checkout__input--label mb-5" for="input1">Address Line 2<span class="checkout__input--label__star">*</span></label>
                        <input class="checkout__input--field border-radius-5" placeholder="Address Line 2" type="text" name="addressLine2" required value={details.addressLine2} onChange={handleInput} />
                        <div class="invalid-feedback">Please enter address line 2</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 mb-20">
                    <div class="checkout__input--list ">
                        <label class="checkout__input--label mb-5" for="input1">Land Mark<span class="checkout__input--label__star">*</span></label>
                        <input class="checkout__input--field border-radius-5" placeholder="Land Mark" type="text" name="landMark" required value={details.landMark} onChange={handleInput} />
                        <div class="invalid-feedback">Please enter nearest land mark</div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mb-20">
                    <div class="checkout__input--list ">
                        <label class="checkout__input--label mb-5" for="input1">City<span class="checkout__input--label__star">*</span></label>
                        <input class="checkout__input--field border-radius-5" placeholder="City" type="text" name="city" required value={details.city} onChange={handleInput} />
                        <div class="invalid-feedback">Please enter city</div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2 mb-20">
                    <div class="checkout__input--list ">
                        <label class="checkout__input--label mb-5" for="input1">Pincode<span class="checkout__input--label__star">*</span></label>
                        <input class="checkout__input--field border-radius-5" placeholder="Pincode" type="text" name="pinCode" required value={details.pinCode} onChange={handleInput} />
                        <div class="invalid-feedback">enter valid pincode</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>State</label>
                        <select name="stateId" class="contact__form--input" required value={details.stateId} onChange={handleInput}>
                            <option value="">Select</option>
                            {States.map(state => <option value={state.id}>{state.name}</option>)}
                        </select>
                        <div class="invalid-feedback">Please select state</div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default function CustomerAddressModal({ showModal, closeModal, handleInput, handleSubmit, validate, details }) {
    return (
        <Modal modal={showModal} >
            <div className=" quickview__main--wrapper">
                <div className="quickview__inner">
                    <Form className='m-2 p-2' noValidate onSubmit={handleSubmit} validated={validate}>
                        <CustomerAddressFrom handleInput={handleInput} handleSubmit={handleSubmit} validate={validate} details={details} />
                        <div className='mt-1'>
                            <Button className='primary__btn' variant='danger' onClick={closeModal}>Close</Button>
                            <Button className='pull-right primary__btn' type='submit'>Save</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </Modal>
    )
}
