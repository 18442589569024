import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "reduxjs-toolkit-persist"
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from "reduxjs-toolkit-persist/es/persistStore"
import reducers from "./reducers";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';

const persistConfig = {
    key: 'padm',
    storage,
    // whitelist: ['storeFront'],
};

const presistedReducers = persistReducer(persistConfig, reducers)

const store = configureStore({
    reducer: presistedReducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

const persistedStore = persistStore(store);
export { store, persistedStore };