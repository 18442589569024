const states = [
    {
        "id": 1,
        "name": "Andaman and Nicobar Island "
    },
    {
        "id": 2,
        "name": "Andhra Pradesh "
    },
    {
        "id": 3,
        "name": " Arunachal Pradesh"
    },
    {
        "id": 4,
        "name": "Assam"
    },
    {
        "id": 5,
        "name": "Bihar"
    },
    {
        "id": 6,
        "name": "Chandigarh"
    },
    {
        "id": 7,
        "name": "Chhattisgarh"
    },
    {
        "id": 8,
        "name": "Dadra and Nagar Haveli"
    },
    {
        "id": 9,
        "name": "Daman and Diu"
    },
    {
        "id": 10,
        "name": "Delhi"
    },
    {
        "id": 11,
        "name": "Goa"
    },
    {
        "id": 12,
        "name": "Gujarat"
    },
    {
        "id": 13,
        "name": "Haryana"
    },
    {
        "id": 14,
        "name": "Himachal Pradesh "
    },
    {
        "id": 15,
        "name": "Jammu and Kashmir"
    },
    {
        "id": 16,
        "name": "Jharkhand"
    },
    {
        "id": 17,
        "name": "Karnataka"
    },
    {
        "id": 18,
        "name": "Kerala"
    },
    {
        "id": 19,
        "name": "Ladakh"
    },
    {
        "id": 20,
        "name": "Lakshadweep"
    },
    {
        "id": 21,
        "name": "Madhya Pradesh"
    },
    {
        "id": 22,
        "name": "Maharashtra"
    },
    {
        "id": 23,
        "name": "Manipur"
    },
    {
        "id": 24,
        "name": "Meghalaya"
    },
    {
        "id": 25,
        "name": "Mizoram"
    },
    {
        "id": 26,
        "name": "Nagaland"
    },
    {
        "id": 27,
        "name": "Odisha"
    },
    {
        "id": 28,
        "name": "Puducherry"
    },
    {
        "id": 29,
        "name": "Punjab"
    },
    {
        "id": 30,
        "name": "Rajasthan"
    },
    {
        "id": 31,
        "name": "Sikkim"
    },
    {
        "id": 32,
        "name": "Tripura"
    },
    {
        "id": 33,
        "name": "Telangana"
    },
    {
        "id": 34,
        "name": "Tamil Nadu"
    },
    {
        "id": 35,
        "name": "Uttar Pradesh"
    },
    {
        "id": 36,
        "name": "Uttarakhand"
    },
    {
        "id": 37,
        "name": "West Bengal"
    },
]

export default states















