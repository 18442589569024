import axios from '../../assets/axios/axios'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import StoresApi from '../../assets/api/StoreApis'

export default function ForgotPassword() {

    const navigate = useNavigate()

    const [validate, setValidate] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isValidEmail, setIsvalidEmail] = useState(false)
    const [isValidOtp, setIsvalidOtp] = useState(false)
    const [otpDetails, setOtpDetails] = useState({})
    const [userOtp, setUserOtp] = useState('')
    const [loginDetails, setLoginDetails] = useState({
        email: '',
        password: '',
        rPassword: ''
    })


    const login = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("Validation fails");
        } else {
            setIsLoading(true)
        }
        setValidate(true)
    }


    const closeModal = () => {
        setIsvalidEmail(false)
        setIsvalidOtp(false)
        setUserOtp('')
        setIsLoading(false)
        // setShowModal(false)
        navigate("/login")
    }

    const handleInput = (e) => {
        setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value })
    }

    const checkEmail = () => {
        axios.post(`${StoresApi.CustomerUsersAuthUrl}/ValidateEmailForPasswordRecovery`, { email: loginDetails.email })
            .then(response => {
                setOtpDetails(response.data.data)
                toast.success("OTP sent to your Email!!", {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                })
                setIsvalidEmail(true)
                setValidate(false)
            })
            .catch(error => {
                console.log(error.response);
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                })
            })
    }

    const validateEmail = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("valid in if");
            setValidate(true)
        } else {
            if (isValidEmail) {
                if (userOtp === otpDetails.otp) {
                    setIsvalidOtp(true)
                    setValidate(false)
                } else {
                    toast.error("OTP not matched, Please enter correct OTP!!", {
                        position: toast.POSITION.TOP_RIGHT,
                        hideProgressBar: true,
                        autoClose: 3000
                    })

                }
                return;
            }
            checkEmail()
        }
    }

    const updatePassword = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("valid in if");
            setValidate(true)
        } else {
            setValidate(true)
            if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,12}$/.test(loginDetails.password)) {
                const msg = "Password must contain minimum eight characters, maximum 12 characters, at least one upppercase letter, at least one lowercase letter and one number and one special character."
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                });
            } else if (loginDetails.password !== loginDetails.rPassword) {
                toast.error("Password did not match: Please try again.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                });
            } else {
                setIsLoading(true)
                const dataObj = {
                    email: loginDetails.email,
                    password: loginDetails.rPassword,
                }
                axios.put(`${StoresApi.CustomerUsersAuthUrl}/UpdatePassword`, dataObj)
                    .then(response => {
                        toast.success("Password update succesfully", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            hideProgressBar: true,
                            autoClose: 3000
                        });
                        closeModal()

                    })
                    .catch(error => {
                        console.log(error.response);
                        setIsLoading(false)
                    })
            }
        }
    }

    return (
        <>
            <div class="login__section section--padding">
                <div class="container">
                    <div class="login__section--inner">
                        <div class="row row-cols-md-2 row-cols-1 justify-content-center">
                            <div class="col">
                                <div class="account__login">
                                    <Form noValidate validated={validate} onSubmit={validateEmail}>
                                        <div class="account__login--header mb-25">
                                            <h2 class="account__login--header__title h3 mb-10">Reset</h2>
                                            <p class="account__login--header__desc">Forgot Password ?</p>
                                        </div>
                                        <div class="account__login--inner">
                                            <label>
                                                <input class="account__login--input" placeholder="Email" type="email" name='email' required disabled={isValidOtp} value={loginDetails.email} onChange={handleInput} />
                                                <div class="invalid-feedback">please enter valid email address</div>
                                            </label>
                                            {isValidEmail ? <label>
                                                <input class="account__login--input" placeholder="OTP" type="text" name='email' required disabled={isValidOtp} value={userOtp} onChange={(e) => setUserOtp(e.target.value)} />
                                                <div class="invalid-feedback">please enter valid OTP</div>
                                            </label> :
                                                <p>You will receive an email containing a OTP allowing you to reset your password to a new preferred one.</p>}
                                            {!(isValidEmail && isValidOtp) && <>
                                                <button class="account__login--btn primary__btn" type="submit" disabled={isLoading} >{isValidEmail ? "Validate Otp" : "Send OTP"}</button>
                                            </>}
                                        </div>
                                    </Form>
                                    {isValidOtp && <Form noValidate validated={validate} onSubmit={updatePassword}>
                                        <div class="account__login--inner">
                                            <label>
                                                <input type="password" class="account__login--input" name="password" placeholder="Passwrod" required value={loginDetails.password} onChange={handleInput} />
                                            </label>
                                            <i class="icon_lock_alt"></i>
                                            <label>
                                                <input type="password" class="account__login--input" name="rPassword" placeholder="Repeat Password" required value={loginDetails.rPassword} onChange={handleInput} />
                                            </label>
                                            <i class="icon_lock_alt"></i>
                                        </div>
                                        <button class="account__login--btn primary__btn" type="submit" disabled={isLoading} >Reset Password</button>
                                    </Form>}
                                    <p class="account__login--signup__text"><button type="button" onClick={() => navigate('/login')}>Login</button></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
