import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import OrderTableDate from './OrderTableDate';
import Pagination from '../../../components/pagination';
import StoresApi from '../../../assets/api/StoreApis';
import axios from '../../../assets/axios/axios';
import Modal from '../../../components/Modal';
import OrderDetails from './OrderDetails';

export default function Orders({ setProfileTab }) {
    const user = useSelector(state => state.auth.currentUser)
    const { store } = useSelector(state => state.storeFront)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [orders, setOrders] = useState([])
    const [orderId, setOrderId] = useState(0)
    const [isDetailsModal, setIsDetailsModal] = useState(false)
    const [toShow, setToShow] = useState([])
    const [currentPage, setCurrentPage] = useState(1);

    const PageSize = 5
    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return toShow.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, toShow]);

    const getCustomerOrders = () => {
        axios.get(`${StoresApi.OrdersUrl}/GetOrdersByCustomerAndStore/${store.id}/${user.customersId}`, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        })
            .then(response => {
                console.log(response);
                setOrders(response.data.data)
                setToShow(response.data.data)
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    useEffect(() => {
        getCustomerOrders()
    }, [])

    const showDetails = (id) => {
        setOrderId(id)
        setIsDetailsModal(true)
    }

    const closeDetails = () => {
        setOrderId(0)
        setIsDetailsModal(false)
    }

    return (
        <>
            <h2 class="account__content--title h3 mb-20">Orders</h2>
            {/* <button class="recharge__btn pull-right primary__btn" type="submit" onClick={() => setFilters(!filters)}>Apply filters</button>
            {filters && <Filters apply={applyFilters} clear={clearFilter} />}
            <div class="container mb-20">
                <ul class="project__tab--one project__tab--btn d-flex mb-40">
                    <li class={`project__tab--btn__list ${tab === 0 ? 'active' : ''}`} onClick={() => applyStatusFilter(0)}>All</li>
                    <li class={`project__tab--btn__list ${tab === 1 ? 'active' : ''}`} onClick={() => applyStatusFilter(1)}>Pending</li>
                    <li class={`project__tab--btn__list ${tab === 2 ? 'active' : ''}`} onClick={() => applyStatusFilter(2)}>Completed</li>
                    <li class={`project__tab--btn__list ${tab === 3 ? 'active' : ''}`} onClick={() => applyStatusFilter(3)}>Cancelled</li>
                    <li class={`project__tab--btn__list ${tab === 4 ? 'active' : ''}`} onClick={() => applyStatusFilter(4)}>Sold out</li>

                </ul>
            </div> */}
            <div class="cart__table">
                <table class="cart__table--inner">
                    <thead class="cart__table--header">
                        <tr class="cart__table--header__items">
                            <th class="cart__table--header__list text-center">Order No</th>
                            <th class="cart__table--header__list text-center">Items</th>
                            <th class="cart__table--header__list text-center">Gross</th>
                            <th class="cart__table--header__list text-center">Discount</th>
                            <th class="cart__table--header__list text-center">Tax</th>
                            <th class="cart__table--header__list text-center">GrandTotal</th>
                            <th class="cart__table--header__list text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody class="cart__table--body text-center">
                        {currentTableData.map(order => {
                            const paid = order.tax ? ((((order.totalAmount / 100) * order.tax) + order.totalAmount) / 100) * 10 : (order.totalAmount / 100) * 10
                            const pending = order.tax ? order.totalAmount - (((((order.totalAmount / 100) * order.tax) + order.totalAmount) / 100) * 10) : order.totalAmount - ((order.totalAmount / 100) * 10)
                            return <OrderTableDate
                                id={order.id}
                                orderId={order.transactionNumber}
                                items={order.orderDetails.length}
                                grossAmount={order.totalAmount}
                                discount={order.discountValue}
                                date={order.transactionDate}
                                grandTotal={order.grandTotal}
                                tax={order.tax}
                                status={order.status}
                                showDetails={showDetails}
                            />
                        })}

                    </tbody>
                </table>

            </div>
            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={toShow.length}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
            />
            <Modal modal={isDetailsModal} setModal={setIsDetailsModal}>
                <div className=" quickview__main--wrapper">
                    <header className="modal-header quickview__header">
                        <button className="close-modal quickview__close--btn" aria-label="close modal" data-close onClick={closeDetails}>✕ </button>
                    </header>
                    <div className="quickview__inner">
                        <OrderDetails orderId={orderId} />
                    </div>
                </div>
            </Modal>
        </>
    )
}
