import React, { useEffect, useState } from 'react'
import axios, { webBaseUrl } from '../../assets/axios/axios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import StoresApi from '../../assets/api/StoreApis'
import { toast } from 'react-toastify'
import { addToCart, buyNow } from '../../redux/slices/cartSlice'
import slugify from '../../utilities/urlCreatore'
import DetailsSection from './DetailsSection'
import parse from 'html-react-parser'
import ListContent from '../list/ListContent'
import Loaders from '../../components/Loaders'
import Error from '../../components/Error'

export default function Details() {

    const [searchParams] = useSearchParams()
    const cart = useSelector(state => state.cart.items)
    const store = useSelector(state => state.storeFront.store)
    const productId = searchParams.get("pid")

    const dispatch = useDispatch()
    let navigate = useNavigate();
    console.log("details page", productId);
    const [tab, setTab] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [product, setProduct] = useState({})
    const [attributeDetails, setAttributeDetails] = useState([])
    const [productOptions, setProductOptions] = useState([])
    const [similarProducts, setSimilarProducts] = useState([])
    const [quantity, setQuantity] = useState('1')
    const [selectedOption, setSelectedOption] = useState([])
    const [submitMode, setSubmitMode] = useState(0)

    const getProductDetails = () => {
        axios.get(`${StoresApi.ProductsUrl}/GetProduct/${productId}/${store.id}`)
            .then(response => {
                const productDetails = response.data.data
                productDetails.inclTax = productDetails.tax > 0 ? ((productDetails.sellingPrice * productDetails.tax) / 100) + productDetails.sellingPrice : productDetails.sellingPrice
                productDetails.displayPrice = productDetails.inclTax
                setProduct(productDetails)
                // groupAttributes(productDetails.productAttributes)
                setAttributeDetails(productDetails.productAttributes)
                setSimilarProducts(productDetails.relatedProducts)
                setProductOptions(productDetails.productOptions)
                setQuantity(productDetails.minimumQuantity)
                setIsLoading(false)
                // window.history.replaceState(null, '', `/details/${slugify(productDetails.name)}?pid=${productDetails.id}`)
                navigate(`/details/${slugify(productDetails.name)}?pid=${productDetails.id}`, { replace: true });
            })
            .catch(error => {
                setIsLoading(false)
                setIsError(true)
                console.log(error);
            })
    }

    const groupAttributes = (attribute) => {
        const arrAttribute = [...attribute]
        const arrAttributesGroup = []
        if (arrAttribute.length > 0) {
            arrAttribute.map((attrib, index) => {
                const indOfAttribute = arrAttributesGroup.findIndex(atr => {
                    if (arrAttributesGroup.length > 0) {
                        return attrib.attributes.attributesGroupId === atr.id
                    }
                    return false;
                })
                const attributeDetailsObj = {
                    name: attrib.attributes.name,
                    value: attrib.value,
                    sort: attrib.sortOrder
                }
                if (indOfAttribute >= 0) {
                    arrAttributesGroup[indOfAttribute].attributeDetails.push(attributeDetailsObj)
                } else {
                    arrAttributesGroup.push({
                        id: attrib.attributes.attributesGroupId,
                        groupName: attrib.attributes.attributesGroup.name,
                        sort: attrib.attributes.sortOrder,
                        attributeDetails: [attributeDetailsObj]
                    })
                }
                return null;
            })
        }
        // console.log(arrAttributesGroup);
        setAttributeDetails(arrAttributesGroup)
    }

    useEffect(() => {
        if (productId) {
            setIsLoading(true)
            getProductDetails()
        } else {
            setIsError(true)
        }
        window.scrollTo(0, 0);
    }, [productId])

    if (isLoading) {
        return <Loaders />
    }

    if (isError) {
        return (
            <>
                <section class="breadcrumb__section breadcrumb__bg" style={{ background: `url(/assets/img/banner/product_banner2.jpg)` }}>
                    <div class="container">
                        <div class="row row-cols-1">
                            <div class="col">
                                <div class="breadcrumb__content">
                                    {/* <h1 class="breadcrumb__content--title mb-10">Product Details</h1> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Error />
            </>
        )
    }


    return (
        <main class="main__content_wrapper">
            <section class="breadcrumb__section breadcrumb__bg" style={{ background: `url(/assets/img/banner/product_banner.jpg)`, backgroundPosition: "center" }}>
                <div class="container">
                    <div class="row row-cols-1">
                        <div class="col">
                            <div class="breadcrumb__content">
                                {/* <h1 class="breadcrumb__content--title mb-10">Product Details</h1> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DetailsSection product={product} setProduct={setProduct} />
            <section class="product__details--tab__section section--padding">
                <div class="container">
                    <div class="row row-cols-1">
                        <div class="col">
                            <ul class="product__tab--one product__details--tab d-flex mb-30">
                                <li class={`product__details--tab__list ${tab === 0 && "active"}`} data-toggle="tab" data-target="#description" onClick={() => setTab(0)}>Description</li>
                                {/* <li class="product__details--tab__list" data-toggle="tab" data-target="#reviews">Product Reviews</li> */}
                                <li class={`product__details--tab__list ${tab === 1 && "active"}`} data-toggle="tab" data-target="#information" onClick={() => setTab(1)}>Specification</li>

                            </ul>
                            <div class="product__details--tab__inner border-radius-10">
                                <div class="tab_content">
                                    {tab === 0 && <div id="description">
                                        <p>{product.description?.includes("<p>") ? parse(product.description) : product.description}</p>
                                    </div>}
                                    {tab === 1 && <div id="information">
                                        <div class="product__tab--conten">
                                            <div class="product__tab--content__step mb-30">
                                                <ul class="additional__info_list">
                                                    {attributeDetails.map(attribute => {
                                                        return (
                                                            <li class="additional__info_list--item">
                                                                <span class="info__list--item-head"><strong>{attribute.attributes.name}</strong></span>
                                                                <span class="info__list--item-content">{attribute.value}</span>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {similarProducts.length > 0 && <section class="product__section section--padding">
                <div class="container">
                    <div class="section__heading text-center mb-50">
                        <h2 class="section__heading--maintitle text__secondary mb-10">Similar Products</h2>
                    </div>
                    <div class="product__inner">
                        <div class="row row-cols-lg-3 row-cols-md-3 row-cols-2 mb--n30">
                            {similarProducts.map(sProduct => {
                                if (sProduct.isDeleted) return null;
                                return (
                                    <div class="col custom-col-2 mb-30">
                                        <ListContent product={sProduct.products} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>}
        </main>
    )
}
