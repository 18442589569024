import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Approval() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <section class="services__section services__section--bg section--padding">
            <div class="container">
                <div class="section__heading text-center mb-50">
                    <h2 class="section__heading--maintitle text__secondary mb-10">Our Approvals</h2>
                </div>
                <div class="services__inner">
                    <div class="row row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-2 mb--n30">
                        <div class="col custom-col mb-30">
                            <article class="services__card">
                                <Link class="services__card--link" target="_blank" to="/assets/uploads/DCD Approval.pdf">
                                    <div class="services__card--content">
                                        <h3 class="services__card--maintitle mb-15">Laboratory Approval</h3>
                                        {/* <p class="services__card--desc mb-15">Every team has Link culture and set of ct ations that have either been aed orsve naturally evolved.</p> */}
                                        <span class="services__card--readmore text__secondary"> View document
                                            <svg class="services__card--readmore__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                                        </span>
                                    </div>
                                </Link>
                            </article>
                        </div>
                        <div class="col custom-col mb-30">
                            <article class="services__card">
                                <Link class="services__card--link" target="_blank" to="/assets/uploads/KSPCB Approval.pdf">
                                    <div class="services__card--content">
                                        <h3 class="services__card--maintitle mb-15">Pollution Control Board Approval </h3>
                                        {/* <p class="services__card--desc mb-15">Every team has Link culture and set of ct ations that have either been aed orsve naturally evolved.</p> */}
                                        <span class="services__card--readmore text__secondary"> View document
                                            <svg class="services__card--readmore__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                                        </span>
                                    </div>
                                </Link>
                            </article>
                        </div>
                        <div class="col custom-col mb-30">
                            <article class="services__card">
                                <Link class="services__card--link" target="_blank" to="/assets/uploads/CDSCO.pdf">
                                    <div class="services__card--content">
                                        <h3 class="services__card--maintitle mb-15">Central Drugs Standard Control Approval</h3>
                                        {/* <p class="services__card--desc mb-15">Every team has Link culture and set of ct ations that have either been aed orsve naturally evolved.</p> */}
                                        <span class="services__card--readmore text__secondary"> View document
                                            <svg class="services__card--readmore__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                                        </span>
                                    </div>
                                </Link>
                            </article>
                        </div>
                        <div class="col custom-col mb-30">
                            <article class="services__card">
                                <Link class="services__card--link" target="_blank" to="/assets/uploads/ISO Certificate_Lab.pdf">
                                    <div class="services__card--content">
                                        <h3 class="services__card--maintitle mb-15">ISO Certification</h3>
                                        {/* <p class="services__card--desc mb-15">Every team has Link culture and set of ct ations that have either been aed orsve naturally evolved.</p> */}
                                        <span class="services__card--readmore text__secondary"> View document
                                            <svg class="services__card--readmore__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                                        </span>
                                    </div>
                                </Link>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
