import React, { useEffect, useState } from 'react'
import axios from '../../assets/axios/axios';
import { useSelector } from 'react-redux';
import StoresApi from '../../assets/api/StoreApis';
import Alphabets from './Alphabets';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Loaders from '../../components/Loaders';

export default function CategoryLists() {
    const [searchParams] = useSearchParams()
    const char = searchParams.get("char")
    const store = useSelector(state => state.storeFront.store)

    const navigate = useNavigate()

    const [categories, setCategories] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [activeChar, setActiveChar] = useState("A")
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const getCategories = async () => {
        try {
            setIsLoading(true)
            const response = await axios.get(`${StoresApi.ProductsCategoryUrl}/GetProductCategoriesByStore/${store.id}`)
            const final = response.data.data.filter(catg => catg.parentCategory !== null)
            setAllCategories(final)
        } catch (err) {
            console.log(err);
            setIsError(true)
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (char) {
            setActiveChar(char)
        }
        getCategories()
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        filterCategories(activeChar)
    }, [allCategories])

    const filterCategories = (char) => {
        const filteredCategories = allCategories.filter(catg => catg.categoryName.toLowerCase().startsWith(char.toLowerCase()))
        // history.replaceState(null, '', `/category?char=${char}`)
        navigate(`/category?char=${char}`, { replace: true });
        setCategories(filteredCategories)
    }

    return (
        <>
            <section class="breadcrumb__section breadcrumb__bg" style={{ background: `url(/assets/img/banner/product_banner.jpg)`, backgroundPosition: "center" }}>
                <div class="container">
                    <div class="row row-cols-1">
                        <div class="col">
                            <div class="breadcrumb__content">
                                {/* <h1 class="breadcrumb__content--title mb-10">{catg}</h1> */}
                                {/* <ul class="breadcrumb__content--menu d-flex">
                                    <li class="breadcrumb__content--menu__items"><a href="index.html">Home</a></li>
                                    <li class="breadcrumb__content--menu__items"><span class="text__secondary">Shop Grid</span></li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {isLoading && <Loaders />}
            {(!isLoading && !isError) && <section className="shop__section section--padding">
                <div className="container">
                    <div className="row">
                        <div className="shop__header shop__header--style2 bg__gray--color align-items-center mb-30">
                            <Alphabets filter={filterCategories} clearFilter={() => { }} activeChar={activeChar} setActiveChar={setActiveChar} />
                        </div>
                        <div class="services__inner">
                            <div class="row row-cols-lg-4 row-cols-md-3 row-cols-sm-3 row-cols-3 mb--n30">
                                {categories.map(catg => {
                                    return (
                                        <div class="col custom-col mb-30">
                                            <Link class="services__card" to={`/products?cid=${catg.id}`}>
                                                <article class="services__card--link">
                                                    <div class="services__card--content">
                                                        <h3 class="services__card--category">{catg.categoryName}</h3>
                                                    </div>
                                                </article>
                                            </Link>
                                        </div>
                                    )
                                })}

                                {/* <div class="col custom-col mb-30">
                                    <article class="services__card">
                                        <a class="services__card--link" href="product-details.html">
                                            <div class="services__card--content">
                                                <h3 class="services__card--category">Advanced Analytic</h3>
                                            </div>
                                        </a>
                                    </article>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </>
    )
}
