import React, { useEffect, useState } from 'react'
import { webBaseUrl } from '../../assets/axios/axios'
import ItemCounter from '../../components/ItemCounter'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import StoresApi from '../../assets/api/StoreApis'
import { toast } from 'react-toastify'
import { addToCart, buyNow } from '../../redux/slices/cartSlice'
import ProductOptions from './ProductOptions'
import { Form } from 'react-bootstrap'
import Modal from '../../components/Modal'
import QuoteModal from '../../components/QuoteModal'
import { addToRfq } from '../../redux/slices/rfqSlice'

export default function DetailsSection({ product = {}, setProduct }) {

    const [searchParams] = useSearchParams()
    const cart = useSelector(state => state.cart.items)
    const rfq = useSelector(state => state.rfq.items)
    const store = useSelector(state => state.storeFront.store)
    const productId = searchParams.get("pid") || product.id
    const [selectedOption, setSelectedOption] = useState([])

    const dispatch = useDispatch()
    let navigate = useNavigate();

    const [validated, setValidated] = useState(false)
    const [isQuoteModal, setIsQuoteModal] = useState(false);
    const [quantity, setQuantity] = useState(1)
    const [submitMode, setSubmitMode] = useState(0)

    const increaseQuantity = (i) => {
        setQuantity(quantity + 1)
    }

    const decreaseQuantity = (i) => {
        setQuantity(quantity - 1)
    }

    const buyNowHandler = () => {
        const addProduct = { ...product }
        addProduct.quantity = +quantity
        addProduct.totalTax = product.tax > 0 ? ((product.sellingPrice * product.tax) / 100) : product.tax
        addProduct.itemTotal = product.displayPrice
        addProduct.totalPrice = addProduct.quantity * addProduct.itemTotal
        addProduct.selectedOptions = selectedOption
        dispatch(buyNow(addProduct))
        const discountDetails = { discount: 0, value: 0 }
        const couponDetails = { id: 0, discountValue: 0, code: '' }
        // history.push("/checkout", { coupons: couponDetails, discounts: discountDetails, totalPrice: addProduct.totalPrice, totalTax: addProduct.totalTax })
        navigate('/cart')
    }

    const toCart = () => {
        const isAdded = cart.findIndex(prod => prod.id === +productId)
        console.log(isAdded);
        if (isAdded >= 0) {
            let addedProducts = cart.filter(prod => prod.id === +productId)
            let exist = false
            addedProducts.forEach(cartProd => {
                if (cartProd.selectedOptions.length > 0) {
                    selectedOption.forEach(selected => {
                        cartProd.selectedOptions.forEach(productOption => {
                            if (productOption.productOptionDetailsId === selected.productOptionDetailsId) {
                                exist = true
                            }
                        });
                    });
                }
                // else {
                //     toast.warn('Product already in Cart!', {
                //         position: toast.POSITION.TOP_RIGHT,
                //         hideProgressBar: true,
                //         autoClose: 3000,
                //         theme: 'dark'
                //     })
                //     return null;
                // }
            });
            if (exist) {
                toast.warn('Product already in Cart with same option!', {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                    theme: 'light'
                })
            } else {
                const addProduct = { ...product }
                addProduct.quantity = +quantity
                addProduct.itemTotal = addProduct.displayPrice
                addProduct.totalPrice = addProduct.quantity * addProduct.itemTotal
                addProduct.selectedOptions = selectedOption
                dispatch(addToCart(addProduct))
            }
        } else {
            const addProduct = { ...product }
            addProduct.quantity = +quantity
            addProduct.itemTotal = addProduct.displayPrice
            addProduct.totalPrice = addProduct.quantity * addProduct.itemTotal
            addProduct.selectedOptions = selectedOption
            dispatch(addToCart(addProduct))
        }
    }

    const updatePrice = (latestPrice) => {
        const objProduct = { ...product }
        const additionTax = objProduct.tax > 0 ? ((+latestPrice * objProduct.tax) / 100) : 0
        objProduct.displayPrice = objProduct.inclTax + latestPrice + additionTax
        objProduct.sellingPrice = objProduct.sellingPrice + latestPrice
        setProduct(objProduct)
    }

    useEffect(() => {
        let addition = 0
        selectedOption.map(option => {
            if (option.type === 3) {
                option.optionDetails.map(details => {
                    addition = details.isSubstractFromPrice ? addition - details.price : addition + details.price
                    return null;
                })
            } else if (option.type === 1) {
                addition = option.optionDetails.isSubstractFromPrice ? addition - option.optionDetails.price : addition + option.optionDetails.price
            }
            return null;
        })
        updatePrice(addition)
    }, [selectedOption])

    const proceed = () => {
        if (submitMode === 1) {
            buyNowHandler()
        }

        if (submitMode === 2) {
            toCart()
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("Validation fails");
        } else {
            proceed()
        }
        setValidated(true)
    }

    const addToList = () => {
        const isAdded = rfq.findIndex(prod => prod.id === +productId)
        if (isAdded >= 0) {
            toast.warn('Product already in RFQ!', {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
                theme: 'light'
            })
        } else {
            const newProduct = { ...product }
            let addProduct = {}
            addProduct.id = newProduct.id
            addProduct.name = newProduct.name
            addProduct.path = newProduct.path
            addProduct.quantity = 1
            addProduct.minimumQuantity = 1
            addProduct.productOptions = newProduct.productOptions
            addProduct.uomTemplateId = newProduct.uomTemplateId
            addProduct.packSize = ""
            dispatch(addToRfq(addProduct))
        }
    }

    return (
        <>
            <section class="product__details--section section--padding">
                <div class="container">
                    <div class="row row-cols-lg-2 row-cols-md-2 row-cols-1">
                        <div class="col">
                            <div class="product__details--media horizontal__style">
                                <div class="product__media--right__horizontal ">
                                    <div class="product__media--preview__horizontal  swiper">
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide">
                                                <div class="product__media--preview__items">
                                                    <a class="product__media--preview__items--link glightbox" data-gallery="product-media-preview" ><img class="product__media--preview__items--img" src={product.path ? `${webBaseUrl}${product.path}` : "/assets/img/logo/logo-default.png"} alt="product-media-img" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="product__details--info">
                                <Form noValidate onSubmit={handleSubmit} validated={validated}>
                                    <h3 class="product__details--info__title mb-15">{product.name}</h3>
                                    <div class="product__details--info__price mb-10">
                                        {!product.requestQuote && <span class="current__price">₹ {product.displayPrice}</span>}
                                    </div>
                                    <div class="product__variant">
                                        <div class="product__variant--list mb-15">
                                            <div class="product__details--info__meta">
                                                <p class="product__details--info__meta--list"><strong>CAS No :</strong>  <span>{product.barCode}</span> </p>
                                                <p class="product__details--info__meta--list"><strong>CAT No :</strong>  <span>{product.sku}</span> </p>
                                                {/* <p class="product__details--info__meta--list"><strong>Vendor:</strong>  <span>Drone</span> </p>
                                                <p class="product__details--info__meta--list"><strong>Type:</strong>  <span>Sofa</span> </p> */}
                                            </div>
                                        </div>
                                        {!product.requestQuote && <div class="row">
                                            <div class="col">
                                                <div class="product__variant--list mb-20">
                                                    <fieldset class="variant__input--fieldset">
                                                        <legend class="product__variant--title mb-8">Pack Size</legend>
                                                        <ProductOptions productOptions={product.productOptions} selectedOption={selectedOption} setSelectedOption={setSelectedOption} uomId={product.uomTemplateId} />
                                                        {/* <Form.Control.Feedback type='invalid'>Please Select Size</Form.Control.Feedback> */}
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="product__variant--list mb-20">
                                                    <fieldset class="variant__input--fieldset">
                                                        <legend class="product__variant--title mb-8">Quantity</legend>
                                                        <ItemCounter quantity={quantity} minQuantity={product.minimumQuantity} increase={increaseQuantity} decrease={decreaseQuantity} />
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>}
                                        {product.requestQuote ? <div class="product__variant--list mb-15">
                                            <button class="vquickview__cart--btn primary__btn mr-30" type="button" onClick={() => setIsQuoteModal(true)}>Request for quote</button>
                                            <button class="vquickview__cart--btn primary__btn" type="button" onClick={() => addToList()}>Add to RFQ list</button>
                                        </div>
                                            :
                                            <div class="product__variant--list mb-15">
                                                <button class="quickview__cart--btn primary__btn" type="submit" onClick={() => setSubmitMode(2)}>Add To Cart</button>
                                                <button class="quickview__cart--btn primary__btn" type="submit" onClick={() => setSubmitMode(1)}>Buy Now</button>
                                            </div>

                                        }
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {isQuoteModal && <Modal modal={isQuoteModal} setModal={setIsQuoteModal}>
                <QuoteModal setOpenModal={setIsQuoteModal} product={product} />
            </Modal>}
        </>
    )
}
