import React from 'react'
import { Link } from 'react-router-dom'

export default function Error() {
    return (
        <section class="error__section section--padding">
            <div class="container">
                <div class="row row-cols-1">
                    <div class="col">
                        <div class="error__content text-center">
                            <img class="error__content--img mb-50" src="/assets/img/other/404-thumb.jpg" alt="error-img" />
                            <h2 class="error__content--title">Opps ! We're not found this page  </h2>
                            <Link class="error__content--btn primary__btn" to="/">Back To Home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
