import axios from '../../assets/axios/axios'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import parse from 'html-react-parser'
import StoresApi from '../../assets/api/StoreApis'
import { setCurrentUser } from '../../redux/slices/authSlice'
import InformationModal from '../../components/InformationModal'

export default function Signup() {
    let navigate = useNavigate();
    const { store } = useSelector(state => state.storeFront)
    const { isLogin } = useSelector((state) => state.auth);

    const dispatch = useDispatch()

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [storeInformation, setStoreInformation] = useState({})
    const [validate, setValidate] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [OtpValidation, setOtpValidation] = useState(false)
    const [loginDetails, setLoginDetails] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        password: '',
        otp: '',
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleInput = (e) => {
        setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value })
    }

    const getOtp = () => {
        const data = {
            firstName: loginDetails.name,
            lastName: "",
            email: loginDetails.email,
            mobileNumber: loginDetails.phoneNumber,
            storeId: store.id
        }
        axios.post(`${StoresApi.CustomerUsersAuthUrl}/ValidateCustomerEmail`, data)
            .then(response => {
                console.log(response);
                if (response.data.success) {
                    setOtpValidation(true)
                    toast.success("OTP sent to your Email", {
                        position: toast.POSITION.TOP_RIGHT,
                        hideProgressBar: true,
                        autoClose: 3000
                    })
                    setValidate(false)
                }
                setIsLoading(false)
            })
            .catch(error => {
                console.log(error.response);
                setIsLoading(false)
                toast.error(error.response?.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                })
            })
    }

    const addCustomer = () => {
        const data = {
            "name": loginDetails.name,
            "email": loginDetails.email,
            "mobileNumber": loginDetails.phoneNumber,
            "password": loginDetails.password,
            "storeId": store.id,
            "otp": loginDetails.otp,
            "address": "",
            "customersId": 0,
            "status": true
        }

        axios.post(`${StoresApi.CustomerUsersAuthUrl}/AddCustomerAndUser`, data)
            .then(response => {
                dispatch(setCurrentUser(response.data.data))
                toast.success("User Created and Loged in successfuly!", {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                })
                setIsLoading(false)
                navigate("/")
            })
            .catch(error => {
                console.log(error.response);
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                })
                setIsLoading(false)
            })
    }

    const login = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("Validation fails");
        } else {
            setIsLoading(true)
            if (!OtpValidation) {
                getOtp()
            } else {
                addCustomer()
            }
        }
        setValidate(true)
    }

    const editDetails = () => {
        setValidate(false)
        setOtpValidation(false)
        setLoginDetails({ ...loginDetails, otp: "" })
    }

    if (isLogin) {
        navigate('/')
    }

    const getSignupPolicy = () => {
        axios.get(`${StoresApi.StoreInformation}/GetSignupPolicyByStoreId/${store.id}`)
            .then(response => {
                setStoreInformation(response.data.data)
                setIsOpenModal(true)
            })
            .catch(error => {
                console.log(error.resposne);
                toast.error("Information not found, please Contact Admin", {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                })
            })
    }

    return (
        <>
            <div class="login__section section--padding">
                <div class="container">
                    <Form noValidate validated={validate} onSubmit={login}>
                        <div class="login__section--inner">
                            <div class="row row-cols-md-2 row-cols-1 justify-content-center">
                                <div class="col">
                                    <div class="account__login register">
                                        <div class="account__login--header mb-25">
                                            <h2 class="account__login--header__title h3 mb-10">Create an Account</h2>
                                            <p class="account__login--header__desc">Register here if you are a new customer</p>
                                        </div>
                                        <div class="account__login--inner">
                                            <label>
                                                <input class="account__login--input" placeholder="Email Address" type="email" name='email' required value={loginDetails.email} onChange={handleInput} disabled={OtpValidation} />
                                                <div class="invalid-feedback">please enter valid email</div>
                                            </label>
                                            {/* <button class="recharge__btn pull-right primary__btn mb-5" type="button" onClick={validatEmail}>Verify</button> */}
                                            <label>
                                                <input class="account__login--input" placeholder="Full Name" type="text" name='name' required value={loginDetails.name} onChange={handleInput} disabled={OtpValidation} />
                                                <div class="invalid-feedback">please enter User name</div>
                                            </label>
                                            <label>
                                                <input class="account__login--input" placeholder="Phone Number" type="text" pattern='[0-9]+' disabled={OtpValidation} maxLength={10} minLength={10} name='mobileNumber' required value={loginDetails.mobileNumber} onChange={handleInput} />
                                                <div class="invalid-feedback">please enter valid Phone Number</div>
                                            </label>
                                            <label>
                                                <input class="account__login--input" placeholder="Password" type="password" name='password' disabled={OtpValidation} required value={loginDetails.password} onChange={handleInput} />
                                                <div class="invalid-feedback">please enter valid password</div>
                                            </label>
                                            {/* <label>
                                                <input class="account__login--input" placeholder="Confirm Password" type="password" required disabled={OtpValidation} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                                <div class="invalid-feedback">please enter retype password</div>
                                            </label> */}
                                            {OtpValidation && <label>
                                                <input class="account__login--input" placeholder="OTP" type="text" required pattern='[0-9]+' maxLength='6' minLength='6' name='otp' value={loginDetails.otp} onChange={handleInput} />
                                                <div class="invalid-feedback">please enter valid 6 digit OTP</div>
                                            </label>}
                                            <div class="account__login--remember position__relative">
                                                <input class="checkout__checkbox--input" id="check2" type="checkbox" required disabled={OtpValidation} />
                                                <span class="checkout__checkbox--checkmark"></span>
                                                <label class="checkout__checkbox--label login__remember--label" htmlFor="check2">
                                                    I have read and agree to the </label><Link to='#' onClick={getSignupPolicy}>Terms & Conditions</Link>
                                                <div class="invalid-feedback">please Agree Terms & Conditions</div>
                                            </div>
                                            <label>
                                                <button class="account__login--btn primary__btn mb-10" type="submit">{OtpValidation ? "Submit & Register" : "Validate Email"}</button>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <InformationModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} title={storeInformation.informationType} content={storeInformation.informationDetails} />
        </>
    )
}
