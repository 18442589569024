import React from 'react'
import { mainMenuRoutes } from '../../routing/routes'
import { Link } from 'react-router-dom'

export default function Menus() {
    return (
        <div className="header__menu d-none d-lg-block">
            <nav className="header__menu--navigation">
                <ul className="d-flex">
                    <li className="header__menu--items">
                        <Link className="header__menu--link" to="/">Home</Link>
                    </li>
                    <li className="header__menu--items">
                        <Link className="header__menu--link" to="/about">About Us</Link>
                    </li>
                    <li className="header__menu--items">
                        <Link className="header__menu--link" to="/category?char=a">Products</Link>
                        {/* <Link className="header__menu--link" to="/products">Products</Link> */}
                    </li>
                    <li className="header__menu--items">
                        <Link className="header__menu--link" to="/services">Services</Link>
                    </li>
                    <li className="header__menu--items">
                        <Link className="header__menu--link" to="/approvals">Approvals</Link>
                    </li>
                    <li className="header__menu--items">
                        <Link className="header__menu--link" to="/qualityPolicy">Quality Policy</Link>
                    </li>
                    <li className="header__menu--items">
                        <Link className="header__menu--link" to="/gallery">Gallery</Link>
                    </li>
                    <li className="header__menu--items">
                        <Link className="header__menu--link" to="/instruments">Instruments</Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}
