import React, { useEffect, useState } from 'react'
import axios from '../../assets/axios/axios'
import parse from 'html-react-parser'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import StoresApi from '../../assets/api/StoreApis'

export default function Aboutus() {
    const store = useSelector(state => state.storeFront.store)

    const [storeInformation, setStoreInformation] = useState()

    const getAboutUs = () => {
        axios.get(`${StoresApi.StoreInformation}/GetAboutUsByStoreId/${store.id}`)
            .then(response => {
                console.log(response);
                setStoreInformation(response.data.data)
            })
            .catch(error => {
                console.log(error);
                toast.error("Information not found, please Contact Admin", {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                })
            })
    }

    useEffect(() => {
        // getAboutUs()
        window.scrollTo(0, 0);
    }, [])

    return (
        <div>
            {storeInformation && parse(storeInformation.informationDetails)}
            <section class="image__with--text__section section--padding">
                <div class="container">
                    <div class="align-items-center">
                        <div class="col mb-25">
                            <div class="image__with--text__content">
                                <h2 class="image__with--text__title mb-18">PADM laboratories, incorporated in January 2015, focuses on wide range of integrated scientific services which includes:</h2>
                                <p class="image__with--text__desc mb-0">a. Impurities characterization</p>
                                <p class="image__with--text__desc mb-0">b. Analytical method development</p>
                                <p class="image__with--text__desc mb-0">c. Analytical method validation</p>
                                <p class="image__with--text__desc mb-0">d. Stability study analysis under cGMP environment</p>
                            </div>
                        </div>
                        <p class="image__with--text__desc mb-0">PADM has state of art laboratory approved by state and central regulatory body. It has all the modern day analytical instruments to fulfill customers every need. Stringent testing and strict documentation practices ensures the high quality of product and services provided to the customers. Meeting committed timelines at all the time with high quality product and service adds credibility to PADM.</p>
                        <p class="image__with--text__desc mb-0">We are specialized in providing high quality product and services with fastest turnaround time.</p>
                    </div>
                </div>
            </section>
        </div>
    )
}
