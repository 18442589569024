import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function RightSide({ setShowSearch }) {
    const cart = useSelector(state => state.cart.items)
    const rfq = useSelector(state => state.rfq.items)
    const { currentUser, isLogin } = useSelector((state) => state.auth);

    return (
        <div className="header__account">
            <ul className="d-flex">
                <li className="header__account--items  header__account--search__items d-md-none" onClick={() => setShowSearch(true)}>
                    <Link className="header__account--btn search__open--btn" to="#" data-offcanvas>
                        <svg className="header__search--button__svg" xmlns="http://www.w3.org/2000/svg" width="26.51" height="23.443" viewBox="0 0 512 512"><path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M338.29 338.29L448 448" /></svg>
                        <span className="visually-hidden">Search</span>
                    </Link>
                </li>
                <li className="header__account--items">
                    <Link className="header__account--btn" to={isLogin ? "/profile" : "/login"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26.51" height="23.443" viewBox="0 0 512 512"><path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /></svg>
                        <span className="visually-hidden">My Account</span>
                    </Link>
                </li>
                <li className="header__account--items">
                    <Link className="header__account--btn minicart__open--btn" to="/cart">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.897" height="21.565" viewBox="0 0 18.897 21.565">
                            <path d="M16.84,8.082V6.091a4.725,4.725,0,1,0-9.449,0v4.725a.675.675,0,0,0,1.35,0V9.432h5.4V8.082h-5.4V6.091a3.375,3.375,0,0,1,6.75,0v4.691a.675.675,0,1,0,1.35,0V9.433h3.374V21.581H4.017V9.432H6.041V8.082H2.667V21.641a1.289,1.289,0,0,0,1.289,1.29h16.32a1.289,1.289,0,0,0,1.289-1.29V8.082Z" transform="translate(-2.667 -1.366)" fill="currentColor" />
                        </svg>
                        <span className="items__count">{cart.length}</span>
                    </Link>
                </li>
                <li className="header__account--items">
                    <Link className="header__account--btn minicart__open--btn" to="/quotations">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.897" height="23.443" viewBox="0 0 31.57 31.13">
                            <path id="ic_email_24px" d="M30.413,4H5.157C3.421,4,2.016,5.751,2.016,7.891L2,31.239c0,2.14,1.421,3.891,3.157,3.891H30.413c1.736,0,3.157-1.751,3.157-3.891V7.891C33.57,5.751,32.149,4,30.413,4Zm0,7.783L17.785,21.511,5.157,11.783V7.891l12.628,9.728L30.413,7.891Z" transform="translate(-2.667 -1.366)" fill="currentColor" />
                        </svg>
                        <span className="items__count">{rfq.length}</span>
                    </Link>
                </li>
            </ul>
        </div>
    )
}
