const version = 'v1'

const StoresApi = {
    StoreUrl: `api/${version}/Store`,
    CustomersUrl: `api/${version}/Customers`,
    PriceListUrl: `api/${version}/PriceList`,
    OrdersUrl: `api/${version}/Orders`,
    ProductsCategoryUrl: `api/${version}/ProductsCategory`,
    ProductsUrl: `api/${version}/Products`,
    PrefixesUrl: `api/${version}/Prefixes`,
    CustomerUsersAuthUrl: `api/CustomerUsersAuth`,
    CustomerAddressesUrl: `api/${version}/CustomerAddresses`,
    CouponsUrl: `api/${version}/Coupons`,
    FreeBirdPrefrenceUrl: `api/${version}/FreeBirdPreference`,
    UOMTemplateUrl: `api/${version}/UOMTemplate`,
    Enquiry: `api/${version}/Enquiry`,
    EnquiryInformation: `api/${version}/EnquiryInformation`,
    StoreInformation: `/api/${version}/StoreInformationConfiguration`,
    FaqType: `api/${version}/FAQType`,
    Faq: `api/${version}/FAQ`
}

export default StoresApi

