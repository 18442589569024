import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import SearchModal from '../../components/SearchModal'
import { useWindowScroll } from '../../hooks/useWindowsScroll'
import Logo from './Logo'
import Menus from './Menus'
import RightSide from './RightSide'
import TopBar from './TopBar'

export default function Header({ setShow }) {
    const scrolled = useWindowScroll();
    const store = useSelector(state => state.storeFront.store)

    const [showSearch, setShowSearch] = useState(false)

    return (
        <>
            <header className="header__section">
                <TopBar />
                <div className={`main__header position__relative header__sticky ${scrolled ? 'sticky' : ''}`}>
                    <div className="container">
                        <div className="main__header--inner d-flex justify-content-between align-items-center">
                            <div className="offcanvas__header--menu__open " onClick={() => setShow(true)}>
                                <a className="offcanvas__header--menu__open--btn" href="javascript:void(0)" data-offcanvas>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="ionicon offcanvas__header--menu__open--svg" viewBox="0 0 512 512"><path fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M80 160h352M80 256h352M80 352h352" /></svg>
                                    <span className="visually-hidden">Offcanvas Menu Open</span>
                                </a>
                            </div>
                            <Logo />
                            <Menus />
                            <RightSide setShowSearch={setShowSearch} />
                        </div>
                    </div>
                </div>
            </header>
            <SearchModal show={showSearch} setShow={setShowSearch} search={() => { }} />
        </>
    )
}
