import React from 'react'
import { Link } from 'react-router-dom';
import usePagination from '../hooks/usePagination';

export default function Pagination(props) {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <>
            <div class="pagination__area bg__gray--color">
                <nav class="pagination">
                    <ul class="pagination__wrapper d-flex align-items-center justify-content-center">
                        <li class="pagination__list" onClick={onPrevious}>
                            <Link to="#" class="pagination__item--arrow  link ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M244 400L100 256l144-144M120 256h292"></path></svg>
                                <span class="visually-hidden">pagination arrow</span>
                            </Link>
                        </li>
                        {paginationRange.map(pageNumber => {
                            // if (pageNumber === DOTS) {
                            //     return <li className="pagination-item dots">&#8230;</li>;
                            // }

                            return (
                                <li class="pagination__list"><span class={`pagination__item pagination__item${pageNumber === currentPage ? '--current' : ''}`} onClick={() => onPageChange(pageNumber)}>{pageNumber}</span></li>
                            );
                        })}
                        <li class="pagination__list" onClick={onNext}>
                            <Link to="#" class="pagination__item--arrow  link ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22.51" height="20.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg>
                                <span class="visually-hidden">pagination arrow</span>
                            </Link>
                        </li><li>
                        </li></ul>
                </nav>
            </div>
        </>
    );
}
