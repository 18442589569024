import React, { useEffect, useState } from 'react'
import axios from '../../assets/axios/axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import StoresApi from '../../assets/api/StoreApis';
import { webBaseUrl } from '../../assets/axios/axios';
import ItemCounter from '../../components/ItemCounter';
import slugify from '../../utilities/urlCreatore';
import { addBusinessDays } from 'date-fns'
import Items from './Items';
import { initializeRazorpay } from '../../assets/razorpay'
import OrderingDetails from './OrderingDetails';
import { addPackSize, decreaseProductQuantityQ, emptyCart, increaseProductQuantityQ, removeFromList } from '../../redux/slices/rfqSlice';
import ProductOptions from '../details/ProductOptions';
import { Button, Form } from 'react-bootstrap';

export default function RFQCart() {
    const cart = useSelector(state => state.rfq.items)
    const { currentUser, isLogin } = useSelector((state) => state.auth);
    const { store } = useSelector(state => state.storeFront)
    const emptyObject = { name: "", email: "", mobileNumber: "", organization: "", address: "", packSize: "", quantity: "" }

    const dispatch = useDispatch()
    const deliveryDate = addBusinessDays(new Date(), 7)
    let navigate = useNavigate();

    const [quoteDetails, setQuoteDetails] = useState(emptyObject)
    const [selectedOption, setSelectedOption] = useState([])
    const [validate, setValidate] = useState(false)


    const handleChange = (e) => {
        setQuoteDetails({ ...quoteDetails, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const removeItem = (i) => {
        dispatch(removeFromList(i))
    }

    const increaseQuantity = (i) => {
        dispatch(increaseProductQuantityQ(i))
    }

    const decreaseQuantity = (i) => {
        dispatch(decreaseProductQuantityQ(i))
    }

    const submit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("Validation fails");
        } else {
            const arrEnquiries = []
            cart.map((item, i) => {
                arrEnquiries.push({
                    "storeId": store.id,
                    "name": "",
                    "productsId": item.id,
                    "packSize": item.packSize,
                    "quantity": item.quantity
                })
            })
            const data = {
                "storeId": store.id,
                "name": quoteDetails.name,
                "mobileNumber": quoteDetails.mobileNumber,
                "email": quoteDetails.email,
                "organization": quoteDetails.organization,
                "address": quoteDetails.address,
                "status": true,
                "enquiries": arrEnquiries
            }

            axios.post(`${StoresApi.EnquiryInformation}/AddEnquirieInformation`, data)
                .then(response => {
                    toast.success("Request sent successfully!!", {
                        position: toast.POSITION.TOP_RIGHT,
                        hideProgressBar: true,
                        autoClose: 3000
                    })
                    dispatch(emptyCart())
                    navigate("/")
                })
                .catch(error => {
                    console.log(error.response);
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        hideProgressBar: true,
                        autoClose: 3000
                    })
                })
        }
        setValidate(true)
    }

    if (cart.length < 1) {
        return (
            <div className='text-center bg_color_2 mb-30'>
                <div className='mb-3'>
                    <img src='/assets/img/banner/empty.png' alt='empty-cart' />
                </div>
                <Link to='/'><button class="primary__btn">Continue Shoping</button></Link>
            </div>
        )
    }

    const handlePackSize = (value, index) => {
        console.log(value, index);
        const packSize = value[0].optionDetails.optionValue
        dispatch(addPackSize({ packSize: packSize, index: index }))
    }

    return (
        <>
            <section class="cart__section section--padding">
                <div class="container-fluid">
                    <Form noValidate validated={validate} onSubmit={submit}>
                        <div className="row mb-5">
                            <div className="col-lg-6 col-md-6">
                                <div className="contact__form--list mb-20">
                                    <label className="contact__form--label">Name <span className="contact__form--label__star">*</span></label>
                                    <input className="contact__form--input" required type="text" name='name' value={quoteDetails.name} onChange={handleChange} />
                                    <div class="invalid-feedback">please enter name</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="contact__form--list mb-20">
                                    <label className="contact__form--label">Email<span className="contact__form--label__star">*</span></label>
                                    <input className="contact__form--input" required type="email" name='email' value={quoteDetails.email} onChange={handleChange} />
                                    <div class="invalid-feedback">please enter email</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="contact__form--list mb-20">
                                    <label className="contact__form--label">Mobile Number <span className="contact__form--label__star">*</span></label>
                                    <input className="contact__form--input" required type="tel" name='mobileNumber' value={quoteDetails.mobileNumber} onChange={handleChange} />
                                    <div class="invalid-feedback">please enter mobile number</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="contact__form--list mb-20">
                                    <label className="contact__form--label">Company Name<span className="contact__form--label__star">*</span></label>
                                    <input className="contact__form--input" required type="text" name='organization' value={quoteDetails.organization} onChange={handleChange} />
                                    <div class="invalid-feedback">please enter company name</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="contact__form--list mb-20">
                                    <label className="contact__form--label">Address<span className="contact__form--label__star">*</span></label>
                                    <textarea className="contact__form--input" required type="text" name='address' value={quoteDetails.address} onChange={handleChange} />
                                    <div class="invalid-feedback">please enter address</div>
                                </div>
                            </div>
                        </div>
                        <div class="cart__section--inner">
                            <h2 class="cart__title mb-40">Request for quotation list</h2>
                            <div class="cart__table">
                                <table class="cart__table--inner">
                                    <thead class="cart__table--header">
                                        <tr class="cart__table--header__items">
                                            <th class="cart__table--header__list">Product</th>
                                            <th class="cart__table--header__list">Pack Size</th>
                                            <th class="cart__table--header__list">Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody class="cart__table--body">
                                        {cart.map((product, ind) => {
                                            return (
                                                <tr class="cart__table--body__items">
                                                    <td class="cart__table--body__list">
                                                        <div class="cart__product d-flex align-items-center">
                                                            <button class="cart__remove--btn" aria-label="search button" type="button" onClick={() => removeItem(ind)}><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16px" height="16px"><path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" /></svg></button>
                                                            <div class="cart__thumbnail">
                                                                <Link to={`/details/${slugify(product.name)}?pid=${product.id}`}>
                                                                    <img class="border-radius-5" src={product.path ? `${webBaseUrl}${product.path}` : "/assets/img/logo/logo-default.png"} alt="product" />
                                                                </Link>
                                                            </div>
                                                            <div class="cart__content">
                                                                <h3 class="cart__content--title h4"><Link to={`/details/${slugify(product.name)}?pid=${product.id}`}>{product.name}</Link></h3>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="cart__table--body__list">
                                                        <ProductOptions productOptions={product.productOptions} selectedOption={selectedOption} setSelectedOption={(value) => handlePackSize(value, ind)} uomId={product.uomTemplateId} />
                                                    </td>
                                                    <td class="cart__table--body__list">
                                                        <ItemCounter quantity={product.quantity} minQuantity={product.minimumQuantity} ind={ind} increase={increaseQuantity} decrease={decreaseQuantity} />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='mt-1'>
                            <Button className='primary__btn' variant='danger'>Cancel</Button>
                            <Button className='pull-right primary__btn' type='submit'>Send Request</Button>
                        </div>
                    </Form>
                </div>
            </section>
        </>
    )
}
