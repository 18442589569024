import React, { useEffect } from 'react'

export default function QualityPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <section class="image__with--text__section section--padding">
                <div class="container">
                    <div class="align-items-center">
                        <p class="image__with--text__desc mb-1 f-20">We are committed to delight the customers by consistently providing high quality services, systems that always meet or exceed Customer’s and regulator’s expectations.</p>
                        <p class="image__with--text__desc mb-1 f-20">To continuously improve the quality of the products and services by applying the principles of cGMP, ISO guide 34 and international guidelines encompassing all quality, legal, regulatory, Environment, health and Safety requirements at work place.</p>
                        <p class="image__with--text__desc mb-1 f-20">We believe that “Quality is a journey not a destination”. Hence, we consciously put our efforts in improvement of quality, services and systems continuously.</p>
                    </div>
                </div>
            </section>
        </>
    )
}
