import { combineReducers } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import cartReducer from './slices/cartSlice'
import storeFrontReducer from './slices/storeSlice'
import rfqReducer from './slices/rfqSlice'

export default combineReducers({
    auth: authReducer,
    storeFront: storeFrontReducer,
    cart: cartReducer,
    rfq: rfqReducer
})