import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    store: {}
}

const storeSlice = createSlice({
    name: 'storeFront',
    initialState,
    reducers: {
        addStore(state, action) {
            state.store = action.payload
        }
    }
})

export const { addStore } = storeSlice.actions
const storeFrontReducer = storeSlice.reducer

export default storeFrontReducer