import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { webBaseUrl } from '../../assets/axios/axios'
import slugify from '../../utilities/urlCreatore'
import Clamp from '../../components/clamp'
import { useDispatch, useSelector } from 'react-redux'
import axios from '../../assets/axios/axios'
import StoresApi from '../../assets/api/StoreApis'
import { toast } from 'react-toastify'
import { addToCart, buyNow } from '../../redux/slices/cartSlice'
import Modal from '../../components/Modal'
import DetailsModal from '../details/DetailsModal'
import DetailsSection from '../details/DetailsSection'

export default function ListContent({ product = {} }) {
    const cart = useSelector(state => state.cart.items)
    const store = useSelector(state => state.storeFront.store)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [selectedProduct, setSelectedProdct] = useState({})
    const [selectedOption, setSelectedOption] = useState([])
    const [isOptionModal, setIsOptionModal] = useState(false);
    const [quantity, setQuantity] = useState(1)
    const [submitMode, setSubmitMode] = useState(0)

    const proceedToBuy = (action, step) => {
        const { productOptions } = product
        if (step === 1 && productOptions.length) {
            // console.log("product has options", productOptions);
            getProductDetails(product.id)
            return null;
        }

        if (action === 1) {
            buyNowHandler(selectedProduct)
        } else {
            toCart(selectedProduct)
        }
    }

    const updatePrice = (latestPrice) => {
        const objProduct = { ...selectedProduct }
        const additionTax = objProduct.tax > 0 ? ((+latestPrice * objProduct.tax) / 100) : 0
        objProduct.displayPrice = objProduct.inclTax + latestPrice + additionTax
        objProduct.sellingPrice = objProduct.sellingPrice + latestPrice
        setSelectedProdct(objProduct)
        // console.log(latestPrice);
    }

    useEffect(() => {
        let addition = 0
        selectedOption.map(option => {
            if (option.type === 3) {
                option.optionDetails.map(details => {
                    addition = details.isSubstractFromPrice ? addition - details.price : addition + details.price
                    return null;
                })
            } else if (option.type === 1) {
                addition = option.optionDetails.isSubstractFromPrice ? addition - option.optionDetails.price : addition + option.optionDetails.price
            }
            return null;
        })
        updatePrice(addition)
    }, [selectedOption])

    const toCart = (product) => {
        const isAdded = cart.findIndex(prod => prod.id === product.id)
        if (isAdded >= 0) {
            toast.warn('Product already in Cart!', {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
                theme: "dark"
            })
        } else {
            const addProduct = { ...product }
            // const price = selectedProduct.displayPrice ? selectedProduct.displayPrice : product.sellingPrice
            addProduct.quantity = +quantity
            addProduct.itemTotal = selectedProduct.displayPrice
            addProduct.totalPrice = addProduct.quantity * addProduct.itemTotal
            // addProduct.totalTax = product.tax > 0 ? ((price * product.tax) / 100) + price : price
            addProduct.selectedOptions = selectedOption
            dispatch(addToCart(addProduct))
        }
    }

    const buyNowHandler = (product) => {
        const addProduct = { ...product }
        const price = selectedProduct.displayPrice ? selectedProduct.displayPrice : product.sellingPrice
        addProduct.quantity = +quantity
        addProduct.itemTotal = selectedProduct.displayPrice
        addProduct.totalTax = product.tax > 0 ? ((price * product.tax) / 100) + price : price
        addProduct.totalPrice = addProduct.quantity * addProduct.itemTotal
        addProduct.selectedOptions = selectedOption
        dispatch(buyNow(addProduct))
        const discountDetails = { discount: 0, value: 0 }
        const couponDetails = { id: 0, discountValue: 0, code: '' }
        // history.push("/checkout", { coupons: couponDetails, discounts: discountDetails, totalPrice: addProduct.totalPrice, totalTax: addProduct.totalTax })
        navigate('/cart')
    }

    const getProductDetails = (productId) => {
        axios.get(`${StoresApi.ProductsUrl}/GetProduct/${productId}/${store.id}`)
            .then(response => {

                const productDetails = response.data.data
                productDetails.inclTax = productDetails.tax > 0 ? ((productDetails.sellingPrice * productDetails.tax) / 100) + productDetails.sellingPrice : productDetails.sellingPrice
                productDetails.displayPrice = productDetails.inclTax
                setSelectedProdct(productDetails)
                setIsOptionModal(true)
                // groupAttributes(productDetails.productAttributes)
                // setSimilarProducts(productDetails.relatedProducts)
                // setProductOptions(productDetails.productOptions)
                // setQuantity(productDetails.minimumQuantity)
                // setIsLoading(false)
            })
            .catch(error => {
                // setIsLoading(false)
                // setIsError(true)
                console.log(error);
            })
    }

    const proceed = () => {
        proceedToBuy(submitMode, 2)
        setIsOptionModal(false)
    }
    return (
        <>
            <article class="product__card">
                <div class="product__card--thumbnail">
                    <Link class="product__card--thumbnail__link display-block" to={`/details/${slugify(product.name)}?pid=${product.id}`}>
                        <img class="product__card--thumbnail__img product__primary--img display-block" src={product.path ? `${webBaseUrl}${product.path}` : "/assets/img/logo/logo-default.png"} style={{ height: "200px" }} alt="product-img" />
                        <img class="product__card--thumbnail__img product__secondary--img display-block" src={product.path ? `${webBaseUrl}${product.path}` : "/assets/img/logo/logo-default.png"} style={{ height: "200px" }} alt="product-img" />
                    </Link>
                </div>
                <div class="product__card--content text-center">
                    <Clamp clamp='2' tag='h3' className='product__card--title product-title'>
                        <Link to={`/details/${slugify(product.name)}?pid=${product.id}`}>
                            {product.name}
                        </Link>
                    </Clamp>
                    <span class="product__card--meta__tag">CAS No : {product.barCode}</span>
                    <div class="product__card--price">
                        <span class="current__price" style={{ height: "25px" }}>{product.requestQuote ? " " : <strong>{`₹ ${product.sellingPrice}`}</strong>}</span>
                    </div>
                    {product.requestQuote ?
                        <button class="primary__btn" onClick={() => getProductDetails(product.id)}>Request for quote</button>
                        :
                        <button class="product__card--btn primary__btn" onClick={() => proceedToBuy(2, 1)}>Add To Cart</button>
                    }
                </div>
            </article>
            {/* <Modal centered show={isOptionModal} onHide={() => setIsOptionModal(false)}>
                <ProductsActionPanel product={selectedProduct} proceed={proceed} setSubmitMode={setSubmitMode} selectedOption={selectedOption} setSelectedOption={setSelectedOption} quantity={quantity} setQuantity={setQuantity} name uomId={selectedProduct.uomTemplateId} />
            </Modal> */}

            {/* <DetailsModal modal={isOptionModal} setModal={setIsOptionModal} product={selectedProduct} setProduct={setSelectedProdct} /> */}
            <Modal modal={isOptionModal} setModal={setIsOptionModal} >
                <div className=" quickview__main--wrapper">
                    <header className="modal-header quickview__header">
                        <button className="close-modal quickview__close--btn" aria-label="close modal" data-close onClick={() => setIsOptionModal(false)}>✕ </button>
                    </header>
                    <div className="quickview__inner">
                        <DetailsSection product={selectedProduct} setProduct={setSelectedProdct} />
                    </div>
                </div>
            </Modal>
        </>
    )
}
