import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import axios from '../../../assets/axios/axios'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import StoresApi from '../../../assets/api/StoreApis'

export default function OrderDetails({ orderId }) {
    const user = useSelector(state => state.auth.currentUser)
    // const printRef = useRef()

    const [orderDetails, setOrderDetails] = useState({})
    const [orderedItems, setOrderedItems] = useState([])

    const getOrderDetails = () => {
        axios.get(`${StoresApi.OrdersUrl}/GetOrder/${orderId}`, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        })
            .then(response => {
                console.log(response);
                setOrderDetails(response.data.data)
                setOrderedItems(response.data.data.orderDetails)
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (orderId) {
            getOrderDetails()
        }
    }, [orderId])

    return (
        <>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="invoice-title add_top_30">
                            <h2 class="text-center">Invoice</h2><h3>Order #{orderDetails.transactionNumber}</h3>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-6">
                                <address>
                                    <strong>Billed To:</strong><br />
                                    {orderDetails.deliveryAddress}<br />
                                </address>
                            </div>
                            <div class="col-6">
                                <address>
                                    <strong>Shipped To:</strong><br />
                                    {orderDetails.deliveryAddress}<br />
                                    {/* 1234 Main<br />
                                    Apt. 4B<br />
                                    Springfield, ST 54321 */}
                                </address>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <address>
                                    <strong>Payment Method:</strong><br />
                                    {orderDetails.orderPayments?.paymentMode === 1 ? "COD" : "Online"}<br />
                                    {/* jsmith@email.com */}
                                </address>
                            </div>
                            <div class="col-6">
                                <address>
                                    <strong>Order Date:</strong><br />
                                    {orderDetails.createdAt && format(new Date(orderDetails.createdAt), 'dd/MM/yyyy')}<br /><br />
                                </address>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="add_top_15">
                            <h3><strong>Order summary</strong></h3>
                            <div class="table-responsive">
                                <table class="w-100">
                                    <thead>
                                        <tr>
                                            <td><strong>Item</strong></td>
                                            <td class="text-center"><strong>Price</strong></td>
                                            <td class="text-center"><strong>Quantity</strong></td>
                                            <td class="text-end"><strong>Totals</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderedItems.map(item => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {item.products.name}
                                                        {item.orderOptionDetails.map(option => {
                                                            return <div className="text-muted text-small" key={option.id}>{option.productOptionDetails ? option.productOptionDetails.optionValue : option.value}</div>
                                                        })}
                                                    </td>
                                                    <td class="text-center">{item.salesPrice}</td>
                                                    <td class="text-center">{item.quantity}</td>
                                                    <td class="text-end">{item.grandTotal}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td class="thick-line"></td>
                                            <td class="thick-line"></td>
                                            <td class="thick-line text-center"><strong>Total</strong></td>
                                            <td class="thick-line text-end">{orderDetails.totalAmount}</td>
                                        </tr>
                                        <tr>
                                            <td class="thick-line"></td>
                                            <td class="thick-line"></td>
                                            <td class="thick-line text-center"><strong>Tax</strong></td>
                                            <td class="thick-line text-end">{orderDetails.tax}</td>
                                        </tr>
                                        {orderDetails.discountValue > 0 && <tr>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line text-center"><strong>Discount</strong></td>
                                            <td class="no-line text-end">{orderDetails.discountValue}</td>
                                        </tr>}
                                        {orderDetails.deliveryCharges > 0 && <tr>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line text-center"><strong>Delivery charges</strong></td>
                                            <td class="no-line text-end">{orderDetails.deliveryCharges}</td>
                                        </tr>}
                                        {orderDetails.additionalCharges > 0 && <tr>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line text-center"><strong>Additional charges</strong></td>
                                            <td class="no-line text-end">{orderDetails.additionalCharges}</td>
                                        </tr>}
                                        <tr>
                                            <td class="no-line"></td>
                                            <td class="no-line"></td>
                                            <td class="no-line text-center"><strong>Grand Total</strong></td>
                                            <td class="no-line text-end">{orderDetails.grandTotal}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <p class="btn_home_align">
                <Link to="#" class="btn_1 outline rounded" onClick={() => { }}>Close</Link>
                <Link to="#" class="btn_1 rounded">Print</Link>
                <ReactToPrint
                    content={() => printRef.current}
                    trigger={() => <Link to="#" class="btn_1 rounded">Print</Link>}
                />
            </p> */}
        </>
    )
}
