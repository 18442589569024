import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';

export const SearchForm = ({ query, setQuery, setShow }) => {
    const navigate = useNavigate()

    const [validate, setValidate] = useState(false)

    const submit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("Validation fails");
        } else {
            navigate(`/search?q=${query}`, { replace: true });
            if (setShow !== undefined) {
                setShow(false)
            }
        }
        setValidate(true)
    }

    return (
        <Form className="predictive__search--form" noValidatev validated={validate} onSubmit={submit}>
            <label>
                <input class="predictive__search--input" placeholder="Search Here" required type="text" value={query} onChange={(e) => setQuery(e.target.value)} />
                {/* <div class="invalid-feedback">please enter charecter</div> */}
            </label>
            <button class="predictive__search--button" aria-label="search button"><svg class="header__search--button__svg" xmlns="http://www.w3.org/2000/svg" width="30.51" height="25.443" viewBox="0 0 512 512"><path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M338.29 338.29L448 448" /></svg>  </button>
        </Form>
    )
}

export default function SearchModal({ show, setShow }) {


    const [query, setQuery] = useState("")


    return (
        <div class={`predictive__search--box ${show && "active"}`} tabindex="-1">
            <div class="predictive__search--box__inner">
                <h2 class="predictive__search--title">Search Products</h2>
                <SearchForm query={query} setQuery={setQuery} setShow={setShow} />
            </div>
            <button class="predictive__search--close__btn" aria-label="search close btn" data-offcanvas onClick={() => { setQuery(""); setShow(false); }}>
                <svg class="predictive__search--close__icon" xmlns="http://www.w3.org/2000/svg" width="40.51" height="30.443" viewBox="0 0 512 512"><path fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368" /></svg>
            </button>
        </div >
    )
}
