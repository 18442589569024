import React, { useEffect } from 'react'

export default function CareerOptions() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <section class="image__with--text__section section--padding">
            <div class="container">
                <div class="align-items-center">
                    <p class="image__with--text__desc mb-1 f-20">Lets grow together</p>
                    <p class="image__with--text__desc mb-1 f-20">We're building a culture at PADM Laboratories Pvt Ltd where amazing people (like you) can do their best work. if you you're ready to grow your career, you've come to right place</p>
                    <p class="image__with--text__desc mb-1 f-20">(Send your updated CV to this Mail id info@padmchem.com)</p>
                </div>
            </div>
        </section>
    )
}
