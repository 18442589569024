import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'

export default function SideBar({ show, setShow }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const navigateToProducts = (id) => {
        // dispatch({ type: CATEGORY_ID, payload: id })
        navigate("/products")
        setShow(false)
    }
    return (
        <div class={`offcanvas-header ${show ? "open" : ""}`} tabindex="-1">
            <div class="offcanvas__inner">
                <div class="offcanvas__logo">
                    <button class="offcanvas__close--btn" onClick={() => setShow(false)}>close</button>
                </div>
                <nav class="offcanvas__menu">
                    <ul class="offcanvas__menu_ul" onClick={() => setShow(false)}>
                        <li className="offcanvas__menu_li">
                            <Link className="offcanvas__menu_item" to="/">Home</Link>
                        </li>
                        <li className="offcanvas__menu_li">
                            <Link className="offcanvas__menu_item" to="/about">About Us</Link>
                        </li>
                        <li className="offcanvas__menu_li">
                            <Link className="offcanvas__menu_item" to="/category?char=a">Products</Link>
                        </li>
                        <li className="offcanvas__menu_li">
                            <Link className="offcanvas__menu_item" to="/services">Services</Link>
                        </li>
                        <li className="offcanvas__menu_li">
                            <Link className="offcanvas__menu_item" to="/approvals">Approvals</Link>
                        </li>
                        <li className="offcanvas__menu_li">
                            <Link className="offcanvas__menu_item" to="/qualityPolicy">Quality Policy</Link>
                        </li>
                        <li className="offcanvas__menu_li">
                            <Link className="offcanvas__menu_item" to="/gallery">Gallery</Link>
                        </li>
                        <li className="offcanvas__menu_li">
                            <Link className="offcanvas__menu_item" to="/instruments">Instruments</Link>
                        </li>
                    </ul>
                    {/* <div class="offcanvas__account--items">
                        <a class="offcanvas__account--items__btn d-flex align-items-center" href="login.html">
                            <span class="offcanvas__account--items__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20.51" height="19.443" viewBox="0 0 512 512"><path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /></svg>
                            </span>
                            <span class="offcanvas__account--items__label">Login / Register</span>
                        </a>
                    </div> */}
                </nav>
            </div>
        </div>
    )
}
