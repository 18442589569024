import React, { useEffect, useState } from 'react'
import axios from '../../assets/axios/axios'
import { useSelector } from 'react-redux'
import StoresApi from '../../assets/api/StoreApis'
import parse from 'html-react-parser'

export default function FAQs() {
    const { store } = useSelector(state => state.storeFront)

    const [faqTypes, setFaqTypes] = useState([])
    const [faqs, setFaqs] = useState([])
    const [activeFaq, setActiveFaq] = useState()

    const getFaqsTypes = () => {
        axios.get(`${StoresApi.FaqType}/GetAllFAQTypesByStore/${store.id}`)
            .then(response => {
                setFaqTypes(response.data.data)
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        getFaqsTypes()
    }, [])

    const getFaqs = (id, ind) => {
        axios.get(`${StoresApi.Faq}/GetFAQsByType/${id}/${store.id}`)
            .then(response => {
                setFaqs(response.data.data)
                setActiveFaq(ind)
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <>
            <main class="main__content_wrapper">
                <section class="faq__section section--padding">
                    <div class="container">
                        <div class="faq__section--inner">
                            <div class="face__step one border-bottom" id="accordionExample">
                                <h3 class="face__step--title mb-30">FAQs</h3>
                                <div class="row">
                                    {faqTypes.map((fType, ind) => {
                                        return (
                                            <div class="col-lg-8">
                                                <div class="accordion__container">
                                                    <div class={`accordion__items ${activeFaq === ind && "active"}`}>
                                                        <h2 class="accordion__items--title" onClick={() => getFaqs(fType.id, ind)}>
                                                            <button class="faq__accordion--btn accordion__items--button">{fType.type}
                                                                <span class="accordion__items--button__icon">
                                                                    <svg class="accordion__items--button__icon--svg" xmlns="http://www.w3.org/2000/svg" width="25.355" height="20.394" viewBox="0 0 512 512"><path d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z" fill="currentColor" /></svg>
                                                                </span>
                                                            </button>
                                                        </h2>
                                                        <div class="accordion__items--body" style={{ display: `${activeFaq === ind ? "block" : "none"}` }}>
                                                            {faqs.map(faq => {
                                                                return (
                                                                    <>
                                                                        <p>
                                                                            <strong><h3>{faq.name}</h3></strong>
                                                                        </p>
                                                                        {parse(faq.details)}
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
