import React, { useEffect, useState } from 'react'
import axios from '../../assets/axios/axios';
import { Button, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import StoresApi from '../../assets/api/StoreApis';
import States from '../../assets/States'
import AddressCard from '../../components/AddressCard';
import { CustomerAddressFrom } from '../../components/CustomerAddressForm';

export default function BillingDetails({ setAddress }) {
    const { store } = useSelector(state => state.storeFront)
    const { isLogin, currentUser } = useSelector(state => state.auth)
    const user = currentUser

    const [selectedAddress, setSelectedAddress] = useState({})
    const [validate, setValidate] = useState(false);
    const [addressType, setAddressType] = useState(1)
    const [customerAddresses, setCustomerAddresses] = useState([])

    const getData = () => {
        axios.get(`${StoresApi.CustomersUrl}/GetCustomer/${user.customersId}`, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        })
            .then(response => {
                setCustomerAddresses(response.data.data.customerAddresses.filter(add => add.isDeleted === false))
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    useEffect(() => {
        if (user.id) {
            getData()
        }
    }, [isLogin])

    const addThisAddess = (addressData) => {
        // const objState = states.find(state => state.id === selectedAddress.stateId)
        const addressString = `${addressData.name}, ${addressData.addressLine1}, ${addressData.addressLine2}, ${addressData.city}-${addressData.pinCode}. ${addressData.mobileNumber}`
        setAddress(addressString)
    }

    const addAddress = (data) => {
        axios.post(`${StoresApi.CustomerAddressesUrl}/AddCustomerAddress`, data)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    const handleAddressSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("Validation fails");
        } else {
            const data = {
                storeId: store.id,
                customerId: currentUser.customersId,
                name: selectedAddress.name,
                mobileNumber: selectedAddress.mobileNumber,
                pinCode: selectedAddress.pinCode,
                addressLine1: selectedAddress.addressLine1,
                addressLine2: selectedAddress.addressLine2,
                landMark: selectedAddress.landMark,
                city: selectedAddress.city,
                stateId: selectedAddress.stateId,
                addressType: selectedAddress.addressType,
                createdBy: currentUser.customersId
            }
            addAddress(data)
            addThisAddess(selectedAddress)
        }
        setValidate(true)
    }

    const handleAddressInput = (e) => {
        const regex = /^[0-9]*$/
        if (e.target.name === 'mobileNumber' || e.target.name === 'pinCode') {
            if (regex.test(e.target.value)) {
                setSelectedAddress({ ...selectedAddress, [e.target.name]: e.target.value })
            }
        } else {
            setSelectedAddress({ ...selectedAddress, [e.target.name]: e.target.value })
        }
    }

    const selectThisAddress = (addressData) => {
        // const objState = states.find(state => state.id === selectedAddress.stateId)
        const addressString = `${addressData.name}, ${addressData.addressLine1}, ${addressData.addressLine2}, ${addressData.city}-${addressData.pinCode}. ${addressData.mobileNumber}`
        setAddress(addressString)
    }

    return (
        <div class="step">
            <Form.Check type="radio" label="Use existing address" id="addressOption1" inline name="addressOption" defaultChecked onChange={() => setAddressType(1)} />
            <Form.Check type="radio" label="Use new address" id="addressOption2" inline name="addressOption" onChange={() => setAddressType(2)} />
            {addressType === 1 && <Row>
                {customerAddresses.length > 0 ? customerAddresses.map((addrs, ind) => {
                    return (
                        <AddressCard address={addrs} ind={ind} key={addrs.id}>
                            <button class="account__details--footer__btn" type="button" onClick={() => selectThisAddress(addrs)} >Use this Address</button>
                        </AddressCard>
                    )
                }) : <p>You dont have any address added</p>}
            </Row>}
            {addressType === 2 && <Form noValidate validated={validate} onSubmit={handleAddressSubmit}>
                <CustomerAddressFrom details={selectedAddress} handleInput={handleAddressInput} />
                <button class="primary__btn pull-right mb-10" type='submit'>Continue</button>
            </Form>}
        </div>
    )
}
