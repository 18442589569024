import React, { useEffect } from 'react'
import InstrumentListContent from './InstrumentListContent'

export default function Instruments() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <section class="cart__section section--padding">
                <div class="container-fluid">
                    <div class="cart__section--inner">
                        <h2 class="cart__title mb-40">Instruments</h2>
                        <div class="row">
                            <div class="cart__table">
                                <table class="cart__table--inner">
                                    <thead class="cart__table--header">
                                        <tr class="cart__table--header__items">
                                            <th class="cart__table--header__list">S.No</th>
                                            <th class="cart__table--header__list">Instruments</th>
                                            <th class="cart__table--header__list">Make/Model</th>
                                        </tr>
                                    </thead>
                                    <tbody class="cart__table--body">
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                1
                                            </td>
                                            <td class="cart__table--body__list">
                                                High Performance liquid Chromatography (HPLC)
                                            </td>
                                            <td class="cart__table--body__list">
                                                Agilent, Shimadzu
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                2
                                            </td>
                                            <td class="cart__table--body__list">
                                                Gas Chromatography with Head Space (GC-HS)
                                            </td>
                                            <td class="cart__table--body__list">
                                                Agilent
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                3
                                            </td>
                                            <td class="cart__table--body__list">
                                                Liquid Chromatography- Mass Spectrometry (LC-MS)
                                            </td>
                                            <td class="cart__table--body__list">
                                                Agilent
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                4
                                            </td>
                                            <td class="cart__table--body__list">
                                                Liquid Chromatography - Tandem Mass Spectrometry (LC-MSMS)
                                            </td>
                                            <td class="cart__table--body__list">
                                                Waters
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                5
                                            </td>
                                            <td class="cart__table--body__list">
                                                Preparative HPLC (PHPLC)
                                            </td>
                                            <td class="cart__table--body__list">
                                                Agilent
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                6
                                            </td>
                                            <td class="cart__table--body__list">
                                                UV – Visible Spectrophotometer
                                            </td>
                                            <td class="cart__table--body__list">
                                                Thermo Scientific Evolution 300
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                7
                                            </td>
                                            <td class="cart__table--body__list">
                                                FTIR
                                            </td>
                                            <td class="cart__table--body__list">
                                                Perkin Elmer
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                8
                                            </td>
                                            <td class="cart__table--body__list">
                                                Dissolution Tester
                                            </td>
                                            <td class="cart__table--body__list">
                                                Electrolab-TDT 08L
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                9
                                            </td>
                                            <td class="cart__table--body__list">
                                                Disintegration Apparatus
                                            </td>
                                            <td class="cart__table--body__list">
                                                Electrolab ED 2L
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                10
                                            </td>
                                            <td class="cart__table--body__list">
                                                KF Titrator
                                            </td>
                                            <td class="cart__table--body__list">
                                                Mettler Toledo
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                11
                                            </td>
                                            <td class="cart__table--body__list">
                                                Friability Apparatus
                                            </td>
                                            <td class="cart__table--body__list">
                                                Electrolab EF2W
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                12
                                            </td>
                                            <td class="cart__table--body__list">
                                                Stability Chamber
                                            </td>
                                            <td class="cart__table--body__list">
                                                Mack MK-2
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                13
                                            </td>
                                            <td class="cart__table--body__list">
                                                Photo Stability Chamber
                                            </td>
                                            <td class="cart__table--body__list">
                                                Mack MK-2
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                14
                                            </td>
                                            <td class="cart__table--body__list">
                                                pH Meter
                                            </td>
                                            <td class="cart__table--body__list">
                                                Eutech Instrument
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                15
                                            </td>
                                            <td class="cart__table--body__list">
                                                Viscometer
                                            </td>
                                            <td class="cart__table--body__list">
                                                Brook field
                                            </td>
                                        </tr>
                                        <tr class="cart__table--body__items">
                                            <td class="cart__table--body__list">
                                                16
                                            </td>
                                            <td class="cart__table--body__list">
                                                Polarimeter
                                            </td>
                                            <td class="cart__table--body__list">
                                                Rudolf Research Analytical
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
