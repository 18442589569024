import React, { useEffect, useState } from 'react'
import axios, { webBaseUrl } from '../../assets/axios/axios'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { decreaseProductQuantity, increaseProductQuantity, removeFromCart } from '../../redux/slices/cartSlice'
import ItemCounter from '../../components/ItemCounter'
import slugify from '../../utilities/urlCreatore'

export default function Items() {
    const cart = useSelector(state => state.cart.items)
    const { currentUser, isLogin } = useSelector((state) => state.auth);
    const dispatch = useDispatch()

    const increaseQuantity = (i) => {
        dispatch(increaseProductQuantity(i))
    }

    const decreaseQuantity = (i) => {
        dispatch(decreaseProductQuantity(i))
    }

    const removeItem = (i) => {
        dispatch(removeFromCart(i))
    }

    return (
        <div class="col-lg-8">
            <div class="cart__table">
                <table class="cart__table--inner">
                    <thead class="cart__table--header">
                        <tr class="cart__table--header__items">
                            <th class="cart__table--header__list">Product</th>
                            <th class="cart__table--header__list">Price</th>
                            <th class="cart__table--header__list">Quantity</th>
                            <th class="cart__table--header__list">Total Price</th>
                        </tr>
                    </thead>
                    <tbody class="cart__table--body">
                        {cart.map((product, ind) => {
                            return (
                                <tr class="cart__table--body__items">
                                    <td class="cart__table--body__list">
                                        <div class="cart__product d-flex align-items-center">
                                            <button class="cart__remove--btn" aria-label="search button" type="button" onClick={() => removeItem(ind)}><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16px" height="16px"><path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" /></svg></button>
                                            <div class="cart__thumbnail">
                                                <Link to={`/details/${slugify(product.name)}?pid=${product.id}`}>
                                                    <img class="border-radius-5" src={product.path ? `${webBaseUrl}${product.path}` : "/assets/img/logo/logo-default.png"} alt="product" />
                                                </Link>
                                            </div>
                                            <div class="cart__content">
                                                <h3 class="cart__content--title h4"><Link to={`/details/${slugify(product.name)}?pid=${product.id}`}>{product.name}</Link></h3>
                                                {product.selectedOptions.map(option => {
                                                    return <span class="cart__content--variant">{option.optionDetails.optionValue}</span>
                                                })}
                                                {/* <span class="cart__content--variant">COLOR: Blue</span>
                                                                        <span class="cart__content--variant">WEIGHT: 2 Kg</span> */}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="cart__table--body__list">
                                        <span class="cart__price">{product.itemTotal}</span>
                                    </td>
                                    <td class="cart__table--body__list">
                                        <ItemCounter quantity={product.quantity} minQuantity={product.minimumQuantity} ind={ind} increase={increaseQuantity} decrease={decreaseQuantity} />
                                    </td>
                                    <td class="cart__table--body__list">
                                        <span class="cart__price end">{product.totalPrice}</span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
