import states from '../assets/States'
import React from 'react'
import { Badge, Button, Card, Col } from 'react-bootstrap'

export default function AddressCard({ address = {}, addEdit, children, ind, editAddress, confirmDeleteAddress, setAsDefaultAddress }) {
    return (
        <Col md='6' key={address.id}>
            <Card className="h-100">
                <Card.Body>
                    {address.isDefault && <div class="pull-right">
                        <span class="product__badge--items sale">Default</span>
                    </div>}
                    <h5>Address#: {ind + 1}</h5>
                    <p className='m-0'>{address.name}</p>
                    <p className='m-0'>{address.mobileNumber}</p>
                    <p className='m-0'>{address.email}</p>
                    <p className='m-0'>{address.addressLine1}, {address.addressLine2}</p>
                    <p className='m-0'>{address.landMark}</p>
                    <p className='m-0'>{address.city}, {address.pinCode}</p>
                    <p className='m-0'>{states.find(state => state.id === address.stateId)?.name}</p>
                    {addEdit && <div class="account__details--footer d-flex">
                        <button class="account__details--footer__btn" type="button" onClick={() => editAddress(ind)}>Edit</button>
                        {/* <button class="account__details--footer__btn" type="button" onClick={() => confirmDeleteAddress(ind)}>Delete</button>
                        {address.isDefault ? null :
                            <button class="account__details--footer__btn" type="button" onClick={() => setAsDefaultAddress(address.id)}>Default</button>
                        } */}
                    </div>}
                    {children}
                </Card.Body>
            </Card>
        </Col>
    )
}
