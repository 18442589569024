import React from 'react'
import parse from 'html-react-parser'
import Modal from './Modal'

export default function InformationModal({ isOpen, setIsOpen, title, content }) {
    return (
        <Modal modal={isOpen} setModal={setIsOpen} >
            <div className=" quickview__main--wrapper">
                <header className="modal-header quickview__header">
                    <button className="close-modal quickview__close--btn" aria-label="close modal" data-close onClick={() => setIsOpen(false)}>✕ </button>
                </header>
                <div className="quickview__inner">
                    <div>
                        {title}
                    </div>
                    {content && parse(content)}
                </div>
            </div>
        </Modal>
    )
}
