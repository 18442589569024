import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import StoresApi from '../../assets/api/StoreApis';
import axios from '../../assets/axios/axios';

export default function UomOption({ handleChange, id, isRequired, option }) {
    const store = useSelector(state => state.storeFront.store)
    const user = useSelector(state => state.auth.currentUser)

    const [uoms, setUoms] = useState([])
    const [details, setDetails] = useState({
        pack: "",
        size: ""
    })

    const GetUOMTemplates = () => {
        axios.get(`${StoresApi.UOMTemplateUrl}/GetUOMTemplate/${id}`)
            .then(response => {

                setUoms(response.data.data.uomTemplateDetails)
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        GetUOMTemplates()
    }, [])

    const handleInput = (e) => {
        let { name, value } = e.target
        const data = { ...details }
        setDetails({ ...details, [name]: value })
        if (name === "size") {
            data.size = value
        } else {
            const id = e.target.options[e.target.selectedIndex].dataset.id;
            data.pack = value
            data.id = id
        }

        data.optionValue = `${data.size} ${data.pack}`
        value = `${data.size} ${data.pack}`
        handleChange(value, data, option)
    }

    return (
        <div className='row'>
            <div class="col-lg-4 pe-0">
                <div class="form-group">
                    <input class="form-control" type="number" required={isRequired} name="size" value={details.size} placeholder="Content" onChange={handleInput} />
                </div>
            </div>
            <div class="col-lg-3 ps-0">
                <select class="form-control" required={isRequired} onChange={handleInput} name="pack" value={details.pack}>
                    <option value="">Select</option>
                    {uoms.map(uom => {
                        return <option value={uom.unitName} data-id={uom.id}>{uom.unitName}</option>
                    })}
                </select>
            </div>
        </div>
    )
}
