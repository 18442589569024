import React, { useEffect, useState } from 'react'
import axios from '../../assets/axios/axios'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import StoresApi from '../../assets/api/StoreApis'
import ListContent from './ListContent'
import AlphabeticList from './AlphabeticList'
import Loaders from '../../components/Loaders'
import { Form } from 'react-bootstrap'
import Error from '../../components/Error'

export default function List() {

    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const catg = window.location.pathname.split("/")[1]
    const store = useSelector(state => state.storeFront.store)
    const catId = searchParams.get("cid")

    const [products, setProducts] = useState([])
    const [allProducts, setAllProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [itemsCount, setItemsCount] = useState(18)
    const [searchQuery, setSearchQuery] = useState("")
    const [isSearched, setIsSearched] = useState(false)
    const [isSearchedOpen, setIsSearchedOpen] = useState(false)
    const [isListOpen, setIsListOpen] = useState(false)
    const [activeChar, setActiveChar] = useState("")
    let imgUrl;

    const getData = (categoryId) => {
        axios.get(`${StoresApi.PriceListUrl}/getCategoryWiseProducts/${store.id}/${categoryId}`)
            .then(response => {
                console.log(response.data.data);
                const activeProducts = response.data.data.filter(product => product.status)
                setProducts(activeProducts)
                setAllProducts(activeProducts)
                // dispatch(addProducts(response.data.data))
            })
            .catch(error => {
                console.log(error);
                setIsError(true)
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        if (!isLoading && catId) {
            setIsLoading(true)
            getData(catId)
            navigate(`/products?cid=${catId}`, { replace: true });
            imgUrl = "/assets/images/products_bck.jpg"
        } else {
            setIsError(true)
        }
    }, [])

    const filterProducts = (char) => {
        const filteredProducts = allProducts.filter(product => product.product.name.toLowerCase().startsWith(char.toLowerCase()))
        setProducts(filteredProducts)
        // return filteredProducts
    }

    const searchProducts = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("vali in if");
        } else {
            const searchedProducts = products.filter(product => product.product.name.toLowerCase().includes(searchQuery.toLowerCase()) || (product.product.barCode && product.product.barCode.includes(searchQuery)))
            setProducts(searchedProducts)
            setIsSearched(true)
        }
    }

    const clearSearch = (clearAll) => {
        setSearchQuery("")
        setIsSearched(false)
        if (clearAll) {
            setProducts(allProducts)
        } else {
            filterProducts(activeChar)
        }
    }

    const loadMore = () => {
        setItemsCount(itemsCount + 18)
    }

    if (isError) return <Error />

    return (
        <>
            <section class="breadcrumb__section breadcrumb__bg" style={{ background: `url(/assets/img/banner/product_banner.jpg)`, backgroundPosition: "center" }}>
                <div class="container">
                    <div class="row row-cols-1">
                        <div class="col">
                            <div class="breadcrumb__content">
                                {/* <h1 class="breadcrumb__content--title mb-10">{catg}</h1> */}
                                {/* <ul class="breadcrumb__content--menu d-flex">
                                    <li class="breadcrumb__content--menu__items"><a href="index.html">Home</a></li>
                                    <li class="breadcrumb__content--menu__items"><span class="text__secondary">Shop Grid</span></li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {isLoading && <Loaders />}
            {(!isLoading && !isError) &&
                <section className="shop__section section--padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* <div className="shop__header shop__header--style2 bg__gray--color align-items-center mb-30">
                                    <div className='d-flex justify-content-between'>
                                        <button class="widget__filter--btn style2 align-items-center" data-offcanvas="" onClick={() => setIsListOpen(!isListOpen)}>
                                            <svg class="widget__filter--btn__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="28" d="M368 128h80M64 128h240M368 384h80M64 384h240M208 256h240M64 256h80"></path><circle cx="336" cy="128" r="28" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="28"></circle><circle cx="176" cy="256" r="28" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="28"></circle><circle cx="336" cy="384" r="28" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="28"></circle></svg>
                                            <span class="widget__filter--btn__text">Search By Alphabet</span>
                                        </button>
                                        <button class="widget__filter--btn style2 align-items-center" data-offcanvas="" onClick={() => setIsSearchedOpen(!isSearchedOpen)}>
                                            <svg class="widget__search--form__btn--svg" xmlns="http://www.w3.org/2000/svg" width="25.51" height="22.443" viewBox="0 0 512 512"><path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"></path><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M338.29 338.29L448 448"></path></svg>
                                            <span class="widget__filter--btn__text">Search</span>
                                        </button>
                                    </div>
                                    {isListOpen && <AlphabeticList filter={filterProducts} clearFilter={clearSearch} activeChar={activeChar} setActiveChar={setActiveChar} />}
                                    {isSearchedOpen && <div class="single__widget widget__bg">
                                        <Form className="widget__search--form" noValidate onSubmit={searchProducts}>
                                            <label>
                                                <input class="widget__search--form__input border-0" placeholder="Search by" required type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                            </label>
                                            <button class="widget__search--form__btn" type="submit" aria-label="search button" >
                                                <svg class="widget__search--form__btn--svg" xmlns="http://www.w3.org/2000/svg" width="25.51" height="22.443" viewBox="0 0 512 512"><path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"></path><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M338.29 338.29L448 448"></path></svg>
                                            </button>
                                            {isSearched && <button class="clear" type="button" aria-label="search button" onClick={clearSearch} >
                                                <svg class="widget__search--form__btn--svg" xmlns="http://www.w3.org/2000/svg" width="25.51" height="22.443" viewBox="0 0 512 512" stroke='currentColor'><path stroke='currentColor' d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" /></svg>
                                            </button>}
                                        </Form>
                                    </div>}
                                </div> */}
                                <div className="shop__product--wrapper">
                                    <div className="tab_content">
                                        <div id="product_grid" className="tab_pane active show">
                                            {/* <NotFound /> */}
                                            <div className="product__section--inner product__grid--inner">
                                                <div className="row row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-2 mb--n30">
                                                    {products.length > 0 ? products.map((product, ind) => {
                                                        if ((ind + 1) <= itemsCount) {
                                                            return (
                                                                <div class="col custom-col-2 mb-30">
                                                                    <ListContent product={product} />
                                                                </div>
                                                            )
                                                        }
                                                    }) :
                                                        <h4 class="text-center add_top_10">{isSearched ? "None of our products match your search criteria!! search with diffrent query" : "Content not Found"}</h4>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {products.length > itemsCount && <div class="account__details--footer d-flex justify-content-center"><button class="account__details--footer__btn" type="button" onClick={loadMore}>Load More</button></div>}
                </section >}
        </>
    )
}
