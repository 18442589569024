import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: []
}

const cartSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        addToCart(state, action) {
            state.items.push(action.payload)
        },
        increaseProductQuantity(state, action) {
            const updateProduct = state.items[action.payload]
            updateProduct.quantity += 1
            updateProduct.totalPrice = updateProduct.itemTotal * updateProduct.quantity
        },
        decreaseProductQuantity(state, action) {
            const updateProduct = state.items[action.payload]
            updateProduct.quantity -= 1
            updateProduct.totalPrice = updateProduct.itemTotal * updateProduct.quantity
        },
        removeFromCart(state, action) {
            state.items.splice(action.payload, 1)
        },
        buyNow(state, action) {
            const cart = []
            cart.push(action.payload)
            state.items = cart
        },
        emptyCart(state, action) {
            const emptyCart = []
            state.items = emptyCart
        }
    }
})

export const { addToCart, increaseProductQuantity, decreaseProductQuantity, removeFromCart, buyNow, emptyCart } = cartSlice.actions
const cartReducer = cartSlice.reducer

export default cartReducer