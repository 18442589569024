import React, { useState } from 'react'
import axios from '../assets/axios/axios'
import { Button, Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import StoresApi from '../assets/api/StoreApis'
import { toast } from 'react-toastify'

export default function QuoteModal({ product, setOpenModal }) {
    const { store } = useSelector(state => state.storeFront)
    const emptyObject = { name: "", email: "", mobileNumber: "", organization: "", address: "", packSize: "", quantity: "" }

    const [quoteDetails, setQuoteDetails] = useState(emptyObject)
    const [validate, setValidate] = useState(false)

    const handleChange = (e) => {
        setQuoteDetails({ ...quoteDetails, [e.target.name]: e.target.value })
    }

    const submit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("Validation fails");
        } else {
            // setIsLoading(true)
            const data = {
                "storeId": store.id,
                "name": quoteDetails.name,
                "mobileNumber": quoteDetails.mobileNumber,
                "email": quoteDetails.email,
                "packSize": quoteDetails.packSize,
                "quantity": quoteDetails.quantity,
                "productsId": product.id,
                "organization": quoteDetails.organization,
                "address": quoteDetails.address,
                "status": true
            }
            axios.post(`${StoresApi.Enquiry}/AddEnquiry`, data)
                .then(response => {
                    toast.success("Request sent successfully!!", {
                        position: toast.POSITION.TOP_RIGHT,
                        hideProgressBar: true,
                        autoClose: 3000
                    })
                    setOpenModal(false)
                })
                .catch(error => {
                    console.log(error.response);
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        hideProgressBar: true,
                        autoClose: 3000
                    })
                    setOpenModal(false)
                })
        }
        setValidate(true)
    }

    return (
        <>
            <div className=" quickview__main--wrapper">
                <div className="quickview__inner">
                    <Form noValidate validated={validate} onSubmit={submit}>
                        <div className="row mb-5">
                            <div className="col-lg-6 col-md-6">
                                <div className="contact__form--list mb-20">
                                    <label className="contact__form--label">Pack Size <span className="contact__form--label__star">*</span></label>
                                    <input type="text" class="contact__form--input" required name="packSize" value={quoteDetails.packSize} onChange={handleChange} />
                                    <div class="invalid-feedback">please enter Pack size</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="contact__form--list mb-20">
                                    <label className="contact__form--label">Quantity <span className="contact__form--label__star">*</span></label>
                                    <input type="text" class="contact__form--input" required name="quantity" value={quoteDetails.quantity} onChange={handleChange} />
                                    <div class="invalid-feedback">please enter quantity</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="contact__form--list mb-20">
                                    <label className="contact__form--label">Name <span className="contact__form--label__star">*</span></label>
                                    <input className="contact__form--input" required type="text" name='name' value={quoteDetails.name} onChange={handleChange} />
                                    <div class="invalid-feedback">please enter name</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="contact__form--list mb-20">
                                    <label className="contact__form--label">Email<span className="contact__form--label__star">*</span></label>
                                    <input className="contact__form--input" required type="email" name='email' value={quoteDetails.email} onChange={handleChange} />
                                    <div class="invalid-feedback">please enter email</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="contact__form--list mb-20">
                                    <label className="contact__form--label">Mobile Number <span className="contact__form--label__star">*</span></label>
                                    <input className="contact__form--input" required type="tel" name='mobileNumber' value={quoteDetails.mobileNumber} onChange={handleChange} />
                                    <div class="invalid-feedback">please enter mobile number</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="contact__form--list mb-20">
                                    <label className="contact__form--label">Company Name<span className="contact__form--label__star">*</span></label>
                                    <input className="contact__form--input" required type="text" name='organization' value={quoteDetails.organization} onChange={handleChange} />
                                    <div class="invalid-feedback">please enter company name</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="contact__form--list mb-20">
                                    <label className="contact__form--label">Address<span className="contact__form--label__star">*</span></label>
                                    <textarea className="contact__form--input" required type="text" name='address' value={quoteDetails.address} onChange={handleChange} />
                                    <div class="invalid-feedback">please enter address</div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-1'>
                            <Button className='primary__btn' variant='danger' onClick={() => setOpenModal(false)}>Cancel</Button>
                            <Button className='pull-right primary__btn' type='submit'>Send Request</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}
