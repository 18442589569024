import React, { useEffect, useState } from 'react'
import axios from '../../assets/axios/axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import StoresApi from '../../assets/api/StoreApis';
import { webBaseUrl } from '../../assets/axios/axios';
import ItemCounter from '../../components/ItemCounter';
import { decreaseProductQuantity, emptyCart, increaseProductQuantity, removeFromCart } from '../../redux/slices/cartSlice';
import slugify from '../../utilities/urlCreatore';
import { addBusinessDays } from 'date-fns'
import Items from './Items';
import { initializeRazorpay } from '../../assets/razorpay'
import OrderingDetails from './OrderingDetails';

export default function Cart() {
    const cart = useSelector(state => state.cart.items)
    const { currentUser, isLogin } = useSelector((state) => state.auth);
    const { store } = useSelector(state => state.storeFront)
    const user = useSelector((state) => state.auth.currentUser);
    const { storeDelivery } = store

    const dispatch = useDispatch()
    const deliveryDate = addBusinessDays(new Date(), 7)
    let navigate = useNavigate();

    const [tab, setTab] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalTax, setTotalTax] = useState(0)
    const [deliveryAddress, setDeliveryAddress] = useState("")
    const [transactionNumber, setTransactionNumber] = useState()
    const [deliveryMode, setDeliveryMode] = useState(1)
    const [paymentMode, setPaymentMode] = useState(2)
    const [checkoutOption, setCheckoutOption] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [showCouponModal, setShowCouponModal] = useState(false)
    const [couponDetails, setCouponDetails] = useState({
        id: 0,
        discountValue: 0,
        code: ''
    })
    const [discountDetails, setDiscountDetails] = useState({
        discount: 0,
        value: 0
    })
    let orderId;

    useEffect(() => {
        let price = 0
        let tax = 0
        cart.map(product => {
            price = (product.sellingPrice * product.quantity) + price
            tax = (((product.sellingPrice * product.quantity) * product.tax) / 100) + tax;
        })
        setTotalPrice(price);
        setTotalTax(tax);
    }, [cart])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const couponDiscount = (data) => {
        const netTotal = totalPrice + totalTax + storeDelivery?.deliveryCharges + storeDelivery?.additionalCharges
        const discountValue = (data.discount * netTotal) / 100
        setCouponDetails({
            id: data.id,
            code: data.couponCode,
            discountValue,
        })
    }

    const removeCouponDiscount = () => {
        setCouponDetails({
            id: 0,
            discountValue: 0,
            code: ''
        })
    }


    const orderPlaced = () => {
        // setShowModal(false)
        // setValidate(false)
        // setPaymentMode()
        // setDeliveryType()
        // setDeliveryDetails({
        //   date: '',
        //   address: ''
        // })
        setIsLoading(false)
        dispatch(emptyCart())
        toast.success("Order Placed Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            autoClose: 3000
        })
        navigate("/")
    }

    const capture = async (response, details) => {
        try {
            const res = await axios.post(`${StoresApi.OrdersUrl}/CaptureRazorpayPayment/${details.key}/${details.secret}/${response.razorpay_payment_id}/${details.id}`, {
                headers: {
                    authorization: `Bearer ${user.token}`
                }
            })
            orderPlaced()
        } catch (err) {
            orderPlaced()
            console.log(err.response);
        }

    }

    const cancelOrder = () => {
        const data = {
            id: orderId,
            ordersStatusId: 5,
            orderDetails: []
        }
        axios.put(`${StoresApi.OrdersUrl}/UpdateOrder`, data, {
            headers: {
                authorization: `Bearer ${user.token}`
            }
        })
            .then(response => {

                setIsLoading(false)
                dispatch(emptyCart())
                toast.error("Order Cancelled, Payment Failed please order again later!!", {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                })
                navigate("/")
            })
            .catch(error => {
                console.log(error.response);
            })
    }

    const makePayment = async (details) => {
        const res = await initializeRazorpay()

        if (!res) {
            alert("Razorpay SDK Failed to load");
            return;
        }

        const options = {
            key: details.key,
            name: "Salaftechnocrats",
            currency: "INR",
            amount: details.grandTotal * 100,
            order_id: details.razorPayOrderId,
            description: "Thankyou for shopping with us!!",
            image: "http://suq.scount.in/assets/images/logo/logo.png",
            handler: (response) => {
                // Validate payment at server - using webhooks is a better idea.
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                capture(response, details)
            },
            prefill: {
                name: store.user.name,
                email: store.user.email,
                contact: store.user.mobileNumber,
            },
            modal: {
                escape: false,
                confirm_close: true,
                ondismiss: () => {
                    cancelOrder()
                }
            }
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        })

        rzp1.open();
    }

    const addOrder = (data) => {
        axios.post(`${StoresApi.OrdersUrl}/AddOrder`, data, {
            headers: {
                authorization: `Bearer ${user.token}`
            }
        })
            .then(response => {
                if (paymentMode === 2) {

                    orderId = response.data.data.id
                    makePayment(response.data.data)
                } else {
                    orderPlaced()
                }
            })
            .catch(error => {
                setIsLoading(false)
                toast.error(error.response?.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000
                })
                console.log(error);
            })
    }

    const placeOrder = () => {
        setIsLoading(true)
        const arrItems = []
        cart.map(product => {
            let uomTemplateDetailsID = 0
            const selectedUom = product.selectedOptions.filter(option => option.type == 2)
            if (selectedUom.length > 0) {
                uomTemplateDetailsID = selectedUom[0].optionDetails.id
            } else {
                const productUom = product.uomTemplate.uomTemplateDetails.filter(temp => temp.sellingUnit)
                uomTemplateDetailsID = productUom[0].id
            }
            arrItems.push({
                // 'path': product.path,
                'priceListDetailsId': product.id,
                'productsId': product.id,
                // 'productCode': product.code,
                // 'productName': product.name,
                // 'categoryName': product.productCategory.categoryName,
                'salePrice': product.sellingPrice,
                // 'stockStatus': product.product.stockStatus.name,
                // 'stock': product.product.quantity,
                // 'minimumQuantity': product.product.minimumQuantity,
                'uomTemplateDetailsID': uomTemplateDetailsID,
                // "uomTemplateDetailsName": "",
                "unitPrice": product.sellingPrice,
                "quantity": product.quantity,
                "salesPrice": product.sellingPrice,
                "totalAmount": product.quantity * product.sellingPrice,
                "discount": 0,
                "orderOptionDetails": product.selectedOptions,
                "discountValue": 0,
                "tax": ((product.quantity * product.sellingPrice) * product.tax) / 100,
                // 'productTax': product.product.tax,
                "grandTotal": product.tax > 0 ? (((product.sellingPrice * product.quantity) * product.tax) / 100) + (product.sellingPrice * product.quantity) : (product.sellingPrice * product.quantity),
                "status": true
            })
            return null;
        })
        const grandTotal = totalPrice + totalTax + storeDelivery.deliveryCharges + storeDelivery.additionalCharges - couponDetails.discountValue - discountDetails.value
        const orderData = {
            "storeId": store.id,
            "customersId": user.customersId,
            "totalAmount": totalPrice,
            "discount": discountDetails.discount,
            "discountValue": discountDetails.discountValue,
            "grandTotal": grandTotal,
            "tax": totalTax,
            "ordersStatusId": 1,
            "transactionNumber": transactionNumber,
            "couponID": couponDetails.id,
            "orderPayments": {
                "paymentMode": paymentMode,
                "amount": grandTotal,
                "status": 4
            },
            "deliveryDate": deliveryDate,
            "deliveryMode": deliveryMode,
            "deliveryCharges": storeDelivery.deliveryCharges,
            "additionalCharges": storeDelivery.additionalCharges,
            "deliveryAddress": deliveryAddress,
            "customerNotes": '',
            "orderType": 2,
            "status": true,
            "orderDetails": arrItems
        }
        addOrder(orderData)
    }

    if (cart.length < 1) {
        return (
            <div className='text-center bg_color_2 mb-30'>
                <div className='mb-3'>
                    <img src='/assets/img/banner/empty.png' alt='empty-cart' />
                </div>
                <Link to='/'><button class="primary__btn">Continue Shoping</button></Link>
            </div>
        )
    }
    return (
        <>
            <section class="cart__section section--padding">
                <div class="container-fluid">
                    <div class="cart__section--inner">

                        <h2 class="cart__title mb-40">Shopping Cart</h2>
                        <div class="row">
                            {tab === 0 && <Items />}
                            {tab === 1 && <OrderingDetails deliveryAddress={deliveryAddress} setDeliveryAddress={setDeliveryAddress} setDeliveryMode={setDeliveryMode} setPaymentMode={setPaymentMode} totalPrice={totalPrice} totalTax={totalTax} placeOrder={placeOrder} isLoading={isLoading} paymentMode={paymentMode} deliveryMode={deliveryMode} />}
                            <div class="col-lg-4">
                                <div class="cart__summary border-radius-10">
                                    {/* <div class="coupon__code mb-30">
                                            <h3 class="coupon__code--title">Coupon</h3>
                                            <p class="coupon__code--desc">Enter your coupon code if you have one.</p>
                                            <div class="coupon__code--field d-flex">
                                                <label>
                                                    <input class="coupon__code--field__input border-radius-5" placeholder="Coupon code" type="text" />
                                                </label>
                                                <button class="coupon__code--field__btn primary__btn" type="submit">Apply Coupon</button>
                                            </div>
                                        </div> */}
                                    <div class="cart__summary--total mb-20">
                                        <table class="cart__summary--total__table">
                                            <tbody>
                                                <tr class="cart__summary--total__list">
                                                    <td class="cart__summary--total__title text-left">Items</td>
                                                    <td class="cart__summary--amount text-right">{cart.length}</td>
                                                </tr>
                                                <tr class="cart__summary--total__list">
                                                    <td class="cart__summary--total__title text-left">Total</td>
                                                    <td class="cart__summary--amount text-right">₹ {totalPrice}</td>
                                                </tr>
                                                <tr class="cart__summary--total__list">
                                                    <td class="cart__summary--total__title text-left">Tax</td>
                                                    <td class="cart__summary--amount text-right">₹ {totalTax}</td>
                                                </tr>
                                                {couponDetails.discountValue > 0 && <tr class="cart__summary--total__list">
                                                    <td class="cart__summary--total__title text-left">Coupon Discount</td>
                                                    <td class="cart__summary--amount text-right">₹ {couponDetails.discountValue}</td>
                                                </tr>}
                                                {storeDelivery.deliveryCharges > 0 && <tr class="cart__summary--total__list">
                                                    <td class="cart__summary--total__title text-left">Delivery Charges</td>
                                                    <td class="cart__summary--amount text-right">₹ {storeDelivery.deliveryCharges}</td>
                                                </tr>}
                                                {storeDelivery.additionalCharges > 0 && <tr class="cart__summary--total__list">
                                                    <td class="cart__summary--total__title text-left">Additional Charges</td>
                                                    <td class="cart__summary--amount text-right">₹ {storeDelivery.additionalCharges}</td>
                                                </tr>}
                                                <tr class="cart__summary--total__list">
                                                    <td class="cart__summary--total__title text-left">GRAND TOTAL</td>
                                                    <td class="cart__summary--amount text-right">₹ {totalPrice > 0 ? (totalPrice + totalTax + storeDelivery.deliveryCharges + storeDelivery.additionalCharges - couponDetails.discountValue - discountDetails.value) : 0}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {tab === 0 ? <div class="cart__summary--footer">
                                        {/* <p class="cart__summary--footer__desc">Shipping & taxes calculated at checkout</p> */}
                                        <ul class="d-flex justify-content-between">
                                            {/* <li><button class="cart__summary--footer__btn primary__btn cart" type="submit">Update Cart</button></li> */}
                                            <li><a class="cart__summary--footer__btn primary__btn checkout" onClick={() => setTab(1)}>Check Out</a></li>
                                        </ul>
                                    </div> :
                                        <a class="previous__link--content" onClick={() => setTab(0)}>Return to cart</a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
