import axios from 'axios';

export const webBaseUrl = 'http://suq.scount.in/';
const URL = 'http://suq.scount.in/WebApi/';
// export const webBaseUrl = 'http://localhost:58796/';
// const URL = 'https://localhost:44330/';


const instance = axios.create({
    baseURL: URL
})

export default instance