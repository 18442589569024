import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { Provider } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';
import { persistedStore, store } from './redux/store';
import RouteIdentifier from './routing/RouteIdentifier';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
        {/* <ToastContainer transition={Slide} newestOnTop /> */}
        <Router>
          <RouteIdentifier />
        </Router>
      </PersistGate>
    </Provider>
    // <Home />
  );
}

export default App;
