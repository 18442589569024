import React, { useEffect, useState } from 'react'
import axios from '../../assets/axios/axios'
import { Form } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SearchForm } from '../../components/SearchModal'
import { useSelector } from 'react-redux'
import StoresApi from '../../assets/api/StoreApis'
import ListContent from '../list/ListContent'
import Loaders from '../../components/Loaders'

export default function Search() {

    const store = useSelector(state => state.storeFront.store)
    const { currentUser, isLogin } = useSelector((state) => state.auth);
    const [searchParams] = useSearchParams()
    const query = searchParams.get("q")
    const navigate = useNavigate()

    const [products, setProducts] = useState([])
    const [allProducts, setAllProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [searchQuery, setSearchQuery] = useState("")

    const getProducts = (data) => {
        axios.post(`${StoresApi.ProductsUrl}/GetProductsOnSearch`, data)
            .then(response => {
                console.log(response);
                const activeProducts = response.data.data.filter(product => product.status)
                setProducts(activeProducts)
                setAllProducts(activeProducts)
                // dispatch(addProducts(response.data.data))
            })
            .catch(error => {
                console.log(error);
                setIsError(true)
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        navigate(`/search?q=${query}`, { replace: true });
        setSearchQuery(query)
    }, [query])

    useEffect(() => {
        const data = {
            storeId: store.id,
            code: query,
            name: query,
            barCode: query
        }
        getProducts(data)
    }, [query])

    return (
        <section className="shop__section section--padding">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="shop__header shop__header--style2 bg__gray--color align-items-center mb-30">
                            <div class="single__widget widget__bg">
                                <SearchForm query={searchQuery} setQuery={setSearchQuery} />
                            </div>
                        </div>
                        {isLoading && <Loaders />}
                        {(!isLoading && !isError) && <div className="shop__product--wrapper">
                            <div className="tab_content">
                                <div id="product_grid" className="tab_pane active show">
                                    <div className="product__section--inner product__grid--inner">
                                        <div className="row row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-2 mb--n30">
                                            {products.length > 0 ? products.map((product, ind) => {
                                                return (
                                                    <div class="col custom-col-2 mb-30">
                                                        <ListContent product={product} />
                                                    </div>
                                                )
                                            }) :
                                                <h4 class="text-center add_top_10">None of our products match your search criteria!! search with diffrent query</h4>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            {/* {products.length > itemsCount && <div class="account__details--footer d-flex justify-content-center"><button class="account__details--footer__btn" type="button" onClick={loadMore}>Load More</button></div>} */}
        </section >
    )
}
