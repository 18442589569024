import Cart from '../pages/cart/Cart'
import Details from '../pages/details/Details'
import Home from '../pages/Home'
import List from '../pages/list/List'
import Login from '../pages/auth/Login'
import SignUp from '../pages/auth/Signup'
import Profile from '../pages/profile/Profile'
import Gallery from '../pages/gallery/Gallery'
import Aboutus from '../pages/information/Aboutus'
import QualityPolicy from '../pages/information/QualityPolicy'
import CategoryLists from '../pages/list/CategoryLists'
import Instruments from '../pages/information/instruments/Instruments'
import Search from '../pages/search/Search'
import ContactUs from '../pages/information/ContactUs'
import Approval from '../pages/information/Approval'
import Servives from '../pages/information/Servives'
import ForgotPassword from '../pages/auth/ForgotPassword'
import FAQs from '../pages/information/FAQs'
import RFQCart from '../pages/cart/RFQCart'
import CareerOptions from '../pages/CareerOptions'
export const routes = [

]

export const mainMenuRoutes = [
    {
        path: '/',
        component: <Home />,
    },
    {
        path: '/products',
        component: <List />,
    },
    {
        path: '/services',
        component: <Servives />,
    },
    {
        path: '/details/:id',
        component: <Details />,
    },
    {
        path: '/cart',
        component: <Cart />,
    },
    {
        path: '/quotations',
        component: <RFQCart />,
    },
    {
        path: '/login',
        component: <Login />,
    },
    {
        path: '/signup',
        component: <SignUp />,
    },
    {
        path: '/resetPassword',
        component: <ForgotPassword />,
    },
    {
        path: '/gallery',
        component: <Gallery />,
    },
    {
        path: '/about',
        component: <Aboutus />,
    },
    {
        path: '/contact',
        component: <ContactUs />,
    },
    {
        path: '/qualityPolicy',
        component: <QualityPolicy />,
    },
    {
        path: '/faqs',
        component: <FAQs />,
    },
    {
        path: '/instruments',
        component: <Instruments />,
    },
    {
        path: '/approvals',
        component: <Approval />,
    },
    {
        path: '/category',
        component: <CategoryLists />,
    },
    {
        path: '/search',
        component: <Search />,
    },
    {
        path: '/career',
        component: <CareerOptions />,
    },
]

export const privateRoutes = [
    { path: '/profile', component: <Profile /> },
]