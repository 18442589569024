import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    items: []
}

const rfqSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        addToRfq(state, action) {
            state.items.push(action.payload)
        },
        increaseProductQuantityQ(state, action) {
            const updateProduct = state.items[action.payload]
            updateProduct.quantity += 1
            updateProduct.totalPrice = updateProduct.itemTotal * updateProduct.quantity
        },
        decreaseProductQuantityQ(state, action) {
            const updateProduct = state.items[action.payload]
            updateProduct.quantity -= 1
            updateProduct.totalPrice = updateProduct.itemTotal * updateProduct.quantity
        },
        removeFromList(state, action) {
            state.items.splice(action.payload, 1)
        },
        requestNow(state, action) {
            const cart = []
            cart.push(action.payload)
            state.items = cart
        },
        emptyCart(state, action) {
            const emptyCart = []
            state.items = emptyCart
        },
        addPackSize(state, action) {
            const updateProduct = state.items[action.payload.index]
            updateProduct.packSize = action.payload.packSize
        }
    }
})

export const { addToRfq, increaseProductQuantityQ, decreaseProductQuantityQ, removeFromList, requestNow, emptyCart, addPackSize } = rfqSlice.actions
const rfqReducer = rfqSlice.reducer

export default rfqReducer