import React, { useEffect, useState } from 'react'
import axios from '../../assets/axios/axios'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import StoresApi from '../../assets/api/StoreApis'
import { setCurrentUser } from '../../redux/slices/authSlice'

export default function Login() {
    let navigate = useNavigate();
    const { store } = useSelector(state => state.storeFront)

    const dispatch = useDispatch()

    const [validated, setValidated] = useState(false);
    const [togglePassword, setTooglePassword] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [user, setUser] = useState({ email: "", password: "" })

    const HideShowPassword = () => setTooglePassword(!togglePassword)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const onSubmit = () => {
        window.localStorage.setItem("user", 1)
        navigate("/")
    }

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("validity fails");
        } else {
            console.log("validity true");
            setIsLoading(true)
            const data = {
                email: user.email,
                mobileNumber: user.email,
                password: user.password,
                storeId: store.id
            }
            axios.post(`${StoresApi.CustomerUsersAuthUrl}/CheckCustomerLogin`, data)
                .then(response => {

                    dispatch(setCurrentUser(response.data.data))
                    navigate(-1)
                    toast.info("loged in succesfuly!", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        hideProgressBar: true,
                        autoClose: 3000
                    })
                })
                .catch(error => {
                    console.log(error.response);
                    if (error.response) {
                        toast.error(error.response.data.message, {
                            position: toast.POSITION.BOTTOM_CENTER,
                            hideProgressBar: true,
                            autoClose: 3000
                        });
                    }
                    setIsLoading(false)
                })
        }

        setValidated(true);
    };

    return (
        <>
            <div class="login__section section--padding">
                <div class="container">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div class="login__section--inner">
                            <div class="row row-cols-md-2 row-cols-1 justify-content-center">
                                <div class="col">
                                    <div class="account__login">
                                        <div class="account__login--header mb-25">
                                            <h2 class="account__login--header__title h3 mb-10">Login</h2>
                                            <p class="account__login--header__desc">Login if you are a returning customer.</p>
                                        </div>
                                        <div class="account__login--inner">
                                            <label>
                                                <input class="account__login--input" placeholder="Email" type="email" required value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} />
                                                <div class="invalid-feedback">please enter valid Email</div>
                                            </label>
                                            <label>
                                                <input class="account__login--input" placeholder="Password" type="password" required value={user.password} onChange={(e) => setUser({ ...user, password: e.target.value })} />
                                                <div class="invalid-feedback">please enter password</div>
                                            </label>
                                            <div class="account__login--remember__forgot mb-15 d-flex justify-content-between align-items-center">
                                                <div class="account__login--remember position__relative">
                                                    <input class="checkout__checkbox--input" id="check1" type="checkbox" />
                                                    <span class="checkout__checkbox--checkmark"></span>
                                                    <label class="checkout__checkbox--label login__remember--label" for="check1">
                                                        Remember me</label>
                                                </div>
                                                <button class="account__login--forgot" type="button" onClick={() => navigate('/resetPassword')}>Forgot Your Password?</button>
                                            </div>
                                            <button class="account__login--btn primary__btn" type="submit" disabled={isLoading} >Login</button>
                                            <p class="account__login--signup__text">Don,t Have an Account? <button type="button" onClick={() => navigate('/signup')}>Sign up now</button></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}
