import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { webBaseUrl } from '../../assets/axios/axios'

export default function Logo() {
    const store = useSelector(state => state.storeFront.store)
    return (
        <div className="main__logo">
            <h1 className="main__logo--title"><Link className="main__logo--link" to="/"><img className="main__logo--img" src={store.path ? `${webBaseUrl}${store.path}` : '/assets/img/logo/logo-default.png'} alt="logo-img" /></Link></h1>
        </div>
    )
}
